import { PropsWithChildren, useContext, useEffect, useState } from 'react';
import logo from '../../assets/images/full logo.png';
import '../../index.css';
import './ComponenetsStyles/Navigation.css';
import { signOut } from 'firebase/auth';
import { auth } from '../../firebase-setup/firebase';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFA } from '../Login/TwoFA';
import { Stack, Typography } from '@mui/material';
import { navigateWithQuery } from '../../utils/utilFuntions';
import { show } from '@intercom/messenger-js-sdk';
import { userDataState } from '../../main';
import { Link } from 'react-router-dom';
import theme from '../../theme';

const Navigation: React.FC<PropsWithChildren> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setValidFactor } = useFA();
  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        setValidFactor(false);
        navigate('/login');
      })
      .catch((error) => {
        console.error('❌ Error signing out:', error);
      });
  };

  const { userData } = useContext(userDataState);
  const [isReenrolling, setIsReenrolling] = useState(false);
  useEffect(() => {
    if (!userData) return;
    setIsReenrolling(userData.requireReenrollment);
  }, [userData]);

  return (
    <>
      {isReenrolling && (
        <Link to={'/reenrollment'}>
          <Stack
            justifyContent={'center'}
            alignItems={'center'}
            p={2}
            bgcolor={theme.palette.primary.main}>
            <Typography
              variant="h3"
              color={'white'}
              textAlign={'center'}
              sx={{
                fontSize: {
                  xs: '1.4rem',
                  sm: '2rem',
                },
              }}>
              Your account requires re-enrollment, click here to finalize the
              process
            </Typography>
          </Stack>
        </Link>
      )}
      <section className="navigation-s">
        <nav className="navigation">
          <a
            onClick={() => {
              navigateWithQuery(navigate, '/', location);
            }}
            className="nav-logo-link">
            <img
              src={logo}
              alt="TPRX Logo"
              loading="lazy"
              className="nav-logo"
            />
          </a>
          <Stack direction="row" spacing={2} alignItems={'center'}>
            <button
              className="cta-main logout-btn"
              onClick={() => {
                show();
              }}>
              Chat Support
            </button>
            <a
              className="nav-link"
              onClick={() => {
                navigateWithQuery(navigate, '/supporthub', location);
              }}>
              Support Hub
            </a>
            <a className="nav-link" onClick={handleLogout}>
              Logout
              <svg
                width="15"
                height="9"
                viewBox="0 0 15 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0.976055 4.987V3.664H11.7131L8.94106 0.891999L9.76006 0.0729996L14.0171 4.33L9.76006 8.587L8.94106 7.768L11.7221 4.987H0.976055Z"
                  fill="CurrentColor"
                />
              </svg>
            </a>
          </Stack>
        </nav>
      </section>
      {props.children}
    </>
  );
};
export default Navigation;
