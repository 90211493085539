import { DocumentData } from 'firebase/firestore';
import { StorageReference } from 'firebase/storage';
import { Dispatch, SetStateAction } from 'react';

export interface FaxFileEntry {
  files: File[]; // or any type you expect for files
  docFax: number;
  docName: string;
}

export interface AllFilesType {
  welcomeEmail: { files: File[] };
  firstFax: FaxFileEntry[];
  faxReminder: FaxFileEntry[];
  callManufactureFax: FaxFileEntry[];
}

// Define a union type for the fax keys
export interface FaxType {
  name: 'firstFax' | 'faxReminder' | 'callManufactureFax';
}

export interface AutomationTableProps {
  selectedClient: DocumentData | null;
  onFilesUpdate?: (filesData: {
    clientFiles: {
      file: StorageReference;
      emailName: string;
      docName: string;
    }[];
    allFiles: AllFilesType;
  }) => void;
  setClientFolder: Dispatch<SetStateAction<string | null>>;
}

export const faxTypes: FaxType[] = [
  { name: 'firstFax' },
  { name: 'faxReminder' },
  { name: 'callManufactureFax' },
];
export const allFilesTpes: { name: string }[] = [
  { name: 'firstFax' },
  { name: 'faxReminder' },
  { name: 'callManufactureFax' },
  { name: 'welcomeEmail' },
];

export function isFaxFileEntry(
  file: { files: File[] } | FaxFileEntry
): file is FaxFileEntry {
  return 'docName' in file && 'docFax' in file;
}
