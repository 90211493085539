/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Grid,
  TextField,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
} from '@mui/material';
import { formValues } from '../../../utils/types';
import { ChangeEvent, useCallback, useRef, useState } from 'react';
import QualificationDialog from '../QualificationDialog';

function MedicareFields({
  values,
  setValues,
  errors,
  setValidStep,
  isMedicarePartD,
  setIsMedicarePartD,
  isMedicarePartC,
  setIsMedicarePartC,
  isSpouseOnSSC,
}: {
  values: formValues;
  setValues: React.Dispatch<React.SetStateAction<formValues>>;
  errors: { [key: string]: string };
  setValidStep: React.Dispatch<React.SetStateAction<boolean>>;
  isMedicarePartD: boolean;
  setIsMedicarePartD: React.Dispatch<React.SetStateAction<boolean>>;
  isMedicarePartC: boolean;
  setIsMedicarePartC: React.Dispatch<React.SetStateAction<boolean>>;
  isSpouseOnSSC: boolean;
}) {
  const [openCostDialog, setOpenCostDialog] = useState<boolean>(false);
  const handleClickOpen = () => {
    setOpenCostDialog(true);
  };
  const [spouseOnSSC, setSpouseOnSSC] = useState<boolean>(true);
  const handleClose = () => {
    setOpenCostDialog(false);
    // setSelectedValue(value);
  };
  const qualificationDialogText = useRef<string>('without Medicare covarage');
  const handleMedicareChange = useCallback(
    (value: string | null, name: string) => {
      setValues((prev) => ({
        ...prev,
        patient: {
          ...prev.patient,
          medicare: {
            ...prev.patient.medicare,
            [name]: value,
          },
        },
      }));
    },
    [setValues]
  );
  return (
    <>
      {isSpouseOnSSC && (
        <Grid item xs={12} md={3} key={'SpouseSSC'}>
          <FormControl required>
            <FormLabel id="Are Both Spouses on Social Security Checks">
              Are Both Spouses on Social Security Checks?
            </FormLabel>
            <RadioGroup
              aria-labelledby="Are Both Spouses on Social Security Checks"
              name="bothSSC"
              sx={{ mt: 2 }}
              value={values.patient.medicare['bothSSC']}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleMedicareChange(e.target.value, 'bothSSC');
                if (e.target.value === 'no') {
                  setSpouseOnSSC(false);
                  setValues((prev) => ({
                    ...prev,
                    patient: {
                      ...prev.patient,
                      medicare: {
                        ...prev.patient.medicare,
                        spouceRecieveSSC: 'no',
                      },
                    },
                  }));
                } else {
                  setSpouseOnSSC(true);
                }
              }}>
              <Stack direction="row" spacing={2}>
                <FormControlLabel
                  value="no"
                  control={<Radio />}
                  label="No"
                  sx={{
                    pr: 2,
                    border: '1px solid #d9d9d9',
                    borderRadius: '4px',
                    backgroundColor: '#fff',
                  }}
                />
                <FormControlLabel
                  value="yes"
                  control={<Radio />}
                  label="Yes"
                  sx={{
                    pr: 2,
                    border: '1px solid #d9d9d9',
                    borderRadius: '4px',
                    backgroundColor: '#fff',
                  }}
                />
              </Stack>
            </RadioGroup>
          </FormControl>
        </Grid>
      )}
      {!spouseOnSSC && isSpouseOnSSC && (
        <Grid item xs={12} md={3} key={'spouceRecieveSSC'}>
          <FormControl required>
            <FormLabel id="spouceRecieveSSC">
              Is one of the spouses going to receive Social Security Checks in
              the upcoming 3 months?
            </FormLabel>
            <RadioGroup
              aria-labelledby="Is one of the spouses going to receive Social Security Checks in the upcoming 3 months? "
              name="spouceRecieveSSC"
              sx={{ mt: 2 }}
              value={values.patient.medicare['spouceRecieveSSC']}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleMedicareChange(e.target.value, 'spouceRecieveSSC');
                if (e.target.value === 'no') {
                  setValidStep(false);
                  (qualificationDialogText.current =
                    'Households with only one spouse on Social Security Checks'),
                    handleClickOpen();
                }
              }}>
              <Stack direction="row" spacing={2}>
                <FormControlLabel
                  value="no"
                  control={<Radio />}
                  label="No"
                  sx={{
                    pr: 2,
                    border: '1px solid #d9d9d9',
                    borderRadius: '4px',
                    backgroundColor: '#fff',
                  }}
                />
                <FormControlLabel
                  value="yes"
                  control={<Radio />}
                  label="Yes"
                  sx={{
                    pr: 2,
                    border: '1px solid #d9d9d9',
                    borderRadius: '4px',
                    backgroundColor: '#fff',
                  }}
                />
              </Stack>
            </RadioGroup>
          </FormControl>
        </Grid>
      )}
      <Grid item xs={12} md={3} key={'medicare partD'}>
        <FormControl required>
          <FormLabel id="medicare partD">Are you on Medicare Part D?</FormLabel>
          <RadioGroup
            aria-labelledby="medicare partD"
            name="partD"
            sx={{ mt: 2 }}
            value={values.patient.medicare['partD']}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              handleMedicareChange(e.target.value, 'partD');
              if (e.target.value === 'no') {
                setIsMedicarePartD(false);
              } else {
                setIsMedicarePartD(true);
              }
            }}>
            <Stack direction="row" spacing={2}>
              <FormControlLabel
                value="no"
                control={<Radio />}
                label="No"
                sx={{
                  pr: 2,
                  border: '1px solid #d9d9d9',
                  borderRadius: '4px',
                  backgroundColor: '#fff',
                }}
              />
              <FormControlLabel
                value="yes"
                control={<Radio />}
                label="Yes"
                sx={{
                  pr: 2,
                  border: '1px solid #d9d9d9',
                  borderRadius: '4px',
                  backgroundColor: '#fff',
                }}
              />
            </Stack>
          </RadioGroup>
        </FormControl>
      </Grid>

      {!isMedicarePartD && (
        <Grid item xs={12} md={3} key={'medicare advantage'}>
          <FormControl required={!isMedicarePartD}>
            <FormLabel id="medicare advantage">
              Are you on Medicare Advantage - Part C
            </FormLabel>
            <RadioGroup
              aria-labelledby="medicare advantage"
              name="advantage"
              sx={{ mt: 2 }}
              value={values.patient.medicare['advantage']}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleMedicareChange(e.target.value, 'advantage');
                e.target.value === 'yes'
                  ? setIsMedicarePartC(true)
                  : setIsMedicarePartC(false);
              }}>
              <Stack direction="row" spacing={2}>
                <FormControlLabel
                  value="no"
                  control={<Radio />}
                  label="No"
                  sx={{
                    pr: 2,
                    border: '1px solid #d9d9d9',
                    borderRadius: '4px',
                    backgroundColor: '#fff',
                  }}
                />
                <FormControlLabel
                  value="yes"
                  control={<Radio />}
                  label="Yes"
                  sx={{
                    pr: 2,
                    border: '1px solid #d9d9d9',
                    borderRadius: '4px',
                    backgroundColor: '#fff',
                  }}
                />
              </Stack>
            </RadioGroup>
          </FormControl>
        </Grid>
      )}
      {!isMedicarePartD && isMedicarePartC && (
        <Grid item xs={12} md={4} key={'medicare advantage coverage'}>
          <FormControl required={isMedicarePartC}>
            <FormLabel id="medicare advantage coverage">
              Does your Medicare advantage have ANY Prescription Coverage for
              BRAND Medications? (Including copays, discounts, deductibles, and
              indemnity):
            </FormLabel>
            <RadioGroup
              aria-labelledby="medicare advantage coverage"
              name="advantageCoverage"
              sx={{ mt: 2 }}
              value={values.patient.medicare['advantageCoverage']}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleMedicareChange(e.target.value, 'advantageCoverage');
              }}>
              <Stack direction="row" spacing={2}>
                <FormControlLabel
                  value="no"
                  control={<Radio />}
                  label="No"
                  sx={{
                    pr: 2,
                    border: '1px solid #d9d9d9',
                    borderRadius: '4px',
                    backgroundColor: '#fff',
                  }}
                />
                <FormControlLabel
                  value="yes"
                  control={<Radio />}
                  label="Yes"
                  sx={{
                    pr: 2,
                    border: '1px solid #d9d9d9',
                    borderRadius: '4px',
                    backgroundColor: '#fff',
                  }}
                />
              </Stack>
            </RadioGroup>
          </FormControl>
        </Grid>
      )}
      <Grid
        item
        xs={12}
        md={isMedicarePartC && !isMedicarePartD ? 2 : 3}
        key={'Do you have Medicare Supplement'}>
        <FormControl required>
          <FormLabel id="Do you have Medicare Supplement">
            Do you have Medicare Supplement{' '}
          </FormLabel>
          <RadioGroup
            aria-labelledby="Do you have Medicare Supplement"
            name="supplement"
            sx={{ mt: 2 }}
            value={values.patient.medicare['supplement']}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              handleMedicareChange(e.target.value, 'supplement');
            }}>
            <Stack direction="row" spacing={2}>
              <FormControlLabel
                value="no"
                control={<Radio />}
                label="No"
                sx={{
                  pr: 2,
                  border: '1px solid #d9d9d9',
                  borderRadius: '4px',
                  backgroundColor: '#fff',
                }}
              />
              <FormControlLabel
                value="yes"
                control={<Radio />}
                label="Yes"
                sx={{
                  pr: 2,
                  border: '1px solid #d9d9d9',
                  borderRadius: '4px',
                  backgroundColor: '#fff',
                }}
              />
            </Stack>
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={3} key={'MBI'} mt={4}>
        <TextField
          error={errors['mbi'] ? true : false}
          helperText={errors['mbi']}
          key={'MBI'}
          fullWidth
          name={'mbi'}
          value={values.patient.medicare['mbi'] || ''}
          label={'MBI#'}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            handleMedicareChange(e.target.value, 'mbi');
          }}
          variant="outlined"
        />
      </Grid>

      <QualificationDialog
        open={openCostDialog}
        handleClose={handleClose}
        qulificationGroup={qualificationDialogText.current}
        isEligible={false}
      />
    </>
  );
}

export default MedicareFields;
