/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-mixed-spaces-and-tabs */
import { ChangeEvent, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ordersDataState, healthcareDataState, userDataState } from '../../../main';
import { useLocation, useNavigate } from 'react-router-dom';
import { fplCalculator, navigateWithQuery } from '../../../utils/utilFuntions';
import '../ComponenetsStyles/Orders.css';
import '../../../App.css';
import { DocumentData, addDoc, collection, doc } from 'firebase/firestore';
import { createOrder, newOrder } from '../../../utils/sfFunctions';
import { db } from '../../../firebase-setup/firebase';
import ConfirmationIcon from '../../../assets/images/ConfirmationIcon.svg';
import emailjs from '@emailjs/browser';
import {
  Box,
  Stack,
  Grid,
  Autocomplete,
  TextField,
  MenuItem,
  Typography,
  Button,
  Link,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import React from 'react';
import QualificationDialog from '../../applicationForm/QualificationDialog';
import VerificationDialog from './VerificationDialog';
import theme from '../../../theme';
import { useCollection } from 'react-firebase-hooks/firestore';
interface GroupedOrders {
  [key: string]: []; // Key is the date as a string
}

function sendDataToEmail(data: any) {
  const emailData = { message: JSON.stringify(data, null, 2) };
  emailjs
    .send('default_service', 'template_l4g4jzk', emailData!, {
      publicKey: 'RTSZKvi6HCOYvdkil',
    })
    .then(
      () => {},
      (error) => {
        console.log('FAILED...', error.text);
      }
    );
}
function Orders({ medicationList = [] }: { medicationList: DocumentData[] | null }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [isSendingOrder, setIsSendingOrder] = useState(false);
  const [isOrderSent, setIsOrderSent] = useState(false);
  const { ordersData } = useContext(ordersDataState);
  const { healthcareData } = useContext(healthcareDataState);
  const { userData, setUserData } = useContext(userDataState);
  const userFpl = useRef<number>(0);
  const [newOrder, setNewOrder] = useState<newOrder>({
    pateintEmail: '',
    patientSSN: '',
    orderItems: [
      {
        doctorName: '',
        medicationName: '',
        medicationStrength: '',
        docFax: '',
        docFacility: '',
      },
      {
        doctorName: '',
        medicationName: '',
        medicationStrength: '',
        docFax: '',
        docFacility: '',
      },
      {
        doctorName: '',
        medicationName: '',
        medicationStrength: '',
        docFax: '',
        docFacility: '',
      },
      {
        doctorName: '',
        medicationName: '',
        medicationStrength: '',
        docFax: '',
        docFacility: '',
      },
    ],
  });

  const [medicareExtraDetails, loadingMedicareExtraDetails] = useCollection(collection(db, 'medicareExtraDetails'), {
    snapshotListenOptions: { includeMetadataChanges: false },
  });
  const [medicareExtraDetailsList, setMedicareExtraDetailsList] = useState<DocumentData[] | null>(null);
  const [medicationNum, setMedicationNum] = useState<number>(4);
  const [medicationStrengths, setMedicationStrengths] = useState<Array<{ strength: string; price: string }[] | null>>(
    Array(4).fill(null)
  );

  const getMedCost = useCallback(
    (medication: string, strength: string) => {
      if (!userData) return null;
      const med = medicationList?.find((item) => item.name === medication);
      if (!med) return null;
      const medOption = med.options.find((item: { strength: string; price: string }) => item.strength === strength);
      return medOption.price;
    },
    [medicationList, userData!.insurance.company, userData!.insurance.product, userData!.insurance.plan]
  );
  const [medCosts, setMedCosts] = useState<Array<string> | null>(Array(4).fill(''));
  const [medicationDetailsText, setMedicationDetailsText] = useState<Array<string>>(Array(4).fill(''));
  const [openVerificationDialog, setOpenVerificationDialog] = useState<boolean>(false);
  const [openEligibilityDialog, setOpenEligibilityDialog] = useState<boolean>(false);

  const handleClickOpen = (type: string) => {
    type === 'eligibility' && setOpenEligibilityDialog(true);
  };

  const handleCostDialogClose = () => {
    setOpenEligibilityDialog(false);
  };
  const handleVerificationDialogClose = () => {
    setOpenVerificationDialog(false);
  };

  const [isFirstTextFieldChanged, setIsFirstTextFieldChanged] = useState([false, false, false, false]);
  const filterMedicationsOptions = (options: any[], state: any) => {
    const inputValue = state.inputValue;
    return options.filter((option) => {
      if (
        option.name.toLowerCase().includes(inputValue.toLowerCase()) ||
        option.genericName.toLowerCase().includes(inputValue.toLowerCase())
      ) {
        return option;
      }
      return false;
    });
  };
  // const filterOptions = createFilterOptions({
  //   matchFrom: 'start',
  // });
  const currentMedName = useRef<string | null>(null);

  useEffect(() => {
    if (!userData) return;
    userFpl.current = fplCalculator(userData.numinhouse, userData.income);
  }, [userData]);

  const handleDocChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, index: number) => {
      const selectedDoc = e.target.value;
      const selectedFacility = healthcareData?.find((item) => `${item.fname} ${item.lname}` === selectedDoc)?.facility;
      const selectedFax = healthcareData?.find((item) => `${item.fname} ${item.lname}` === selectedDoc)?.fax;

      setNewOrder((prev) => {
        const updatedOrderItems = [...prev.orderItems];
        updatedOrderItems[index] = {
          ...updatedOrderItems[index],
          doctorName: selectedDoc,
          docFacility: selectedFacility,
          docFax: selectedFax,
        };

        return {
          ...prev,
          orderItems: updatedOrderItems,
        };
      });
    },
    [healthcareData]
  );

  const handleMedicationChange = useCallback(
    (value: any | null, index: number) => {
      if (!medicationList) return;

      // Handle the case when value is null (medication is being removed)
      if (value === null) {
        setNewOrder((prev) => {
          const updatedOrderItems = [...prev.orderItems];
          updatedOrderItems[index] = {
            ...updatedOrderItems[index],
            medicationName: '',
            medicationStrength: '',
          };
          return {
            ...prev,
            orderItems: updatedOrderItems,
          };
        });

        currentMedName.current = null;

        setMedicationStrengths((prev) => {
          const newStrengths = [...prev];
          newStrengths[index] = null;
          return newStrengths;
        });

        setMedicationDetailsText((prev) => {
          const newDetails = [...prev];
          newDetails[index] = '';
          return newDetails;
        });

        setMedCosts((prev) => {
          const newCosts = [...(prev ?? [])];
          newCosts[index] = '';
          return newCosts;
        });

        return;
      }

      medCosts![index] = 'Select Medication & Strength';

      setNewOrder((prev) => {
        const updatedOrderItems = [...prev.orderItems];
        updatedOrderItems[index] = {
          ...updatedOrderItems[index],
          medicationName: value.name + ` (${value.genericName})`,
        };
        return {
          ...prev,
          orderItems: updatedOrderItems,
        };
      });

      currentMedName.current = value.name + `(${value.genericName})`;

      setMedicationStrengths((prev) => {
        const newStrengths = [...prev];
        newStrengths[index] = value?.options || null;
        return newStrengths;
      });
      setMedicationDetailsText((prev) => {
        const newDetails = [...prev];
        if (value?.brand) {
          newDetails[index] =
            'Once your healthcare provider complete our simple steps, excpet to get your medication in about 3 weeks';
        } else {
          newDetails[index] =
            'Once your healthcare provider complete our simple steps, excpet to get your medication in about 1 weeks';
        }
        return newDetails;
      });
      setIsFirstTextFieldChanged((prev) => {
        const newIsFirstTextFieldChanged = [...prev];
        newIsFirstTextFieldChanged[index] = true;
        return newIsFirstTextFieldChanged;
      });
      if (value && value.brand) {
        let isntEligible = false;
        if (value.name === 'Xolair') {
          Number(userData!.income) < 150000 ? (isntEligible = false) : (isntEligible = true);
        } else {
          isntEligible = userFpl.current > value.fpl;
        }

        if (isntEligible) {
          setOpenEligibilityDialog(isntEligible);
          setNewOrder((prev) => {
            const newOrder = { ...prev };
            newOrder.orderItems[index].medicationName = '';
            return newOrder;
          });

          setMedicationStrengths((prev) => {
            const newStrengths = [...prev];
            newStrengths[index] = null;
            return newStrengths;
          });
        }
      }
    },
    [userFpl.current, medCosts, medicationList, userData?.income]
  );
  const handleStrengthChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, index: number) => {
      const selectedStrength = e.target.value;

      setNewOrder((prev) => {
        const updatedOrderItems = [...prev.orderItems]; // Create a new copy of orderItems
        updatedOrderItems[index] = {
          ...updatedOrderItems[index], // Copy the existing orderItem
          medicationStrength: selectedStrength,
        };
        return {
          ...prev,
          orderItems: updatedOrderItems, // Return the new state object with updated orderItems
        };
      });
      setMedCosts((prev) => {
        const newCosts = [...(prev ?? [])];
        newCosts[index] = getMedCost(newOrder.orderItems[index].medicationName, selectedStrength);
        return newCosts;
      });
    },
    [getMedCost, newOrder.orderItems]
  );

  const groupOrdersByDate = (orders: DocumentData[]): GroupedOrders => {
    return orders.reduce((acc, order) => {
      // Format the timestamp as a date string (e.g., '2023-03-25')
      const dateStr = order.timestamp.split('T')[0];

      // If the date key doesn't exist, initialize it with an empty array
      if (!acc[dateStr]) {
        acc[dateStr] = [];
      }
      // Add the order to the appropriate date key
      acc[dateStr].push(order);

      return acc;
    }, {} as GroupedOrders);
  };
  const [groupedOrders, setGroupedOrders] = useState<GroupedOrders>({});
  useEffect(() => {
    if (!ordersData) return;
    const grouped = groupOrdersByDate(ordersData);
    setGroupedOrders(grouped);
  }, []);

  const validateOrder = useCallback(() => {
    let allOrdersValid = true;
    let allItemsEmpty = true;
    let allMedicareDetailsValid = true;

    newOrder.orderItems.forEach((orderItem) => {
      const isEmpty =
        orderItem.doctorName === '' && orderItem.medicationName === '' && orderItem.medicationStrength === '';

      const isPartial =
        (orderItem.doctorName !== '' && (orderItem.medicationName === '' || orderItem.medicationStrength === '')) ||
        (orderItem.medicationName !== '' && (orderItem.doctorName === '' || orderItem.medicationStrength === '')) ||
        (orderItem.medicationStrength !== '' && (orderItem.doctorName === '' || orderItem.medicationName === ''));

      if (!isEmpty) {
        allItemsEmpty = false;
      }

      if (isPartial) {
        allOrdersValid = false;
      }

      // Check Medicare extra details
      if (medicareExtraDetailsList && orderItem.medicationName) {
        const extraFieldDetails = medicareExtraDetailsList.filter((med) =>
          med.medications.includes(orderItem.medicationName.split(' (')[0])
        );

        if (extraFieldDetails.length > 0) {
          const detail = extraFieldDetails[0];

          const skipDonutHoleValidation =
            detail.id === 'donutHole' && userData?.medicare?.partD === 'no' && userData?.medicare?.advantage === 'no';

          const skipSpentValidation = detail.id === 'spent7.5' && userData?.medicare?.partD === 'no';

          if (!skipDonutHoleValidation && !skipSpentValidation) {
            const userAnswer = userData?.medicareExtraDetails?.[detail.id] ? 'yes' : 'no';
            if (userAnswer !== detail.requiredAnswer) {
              allMedicareDetailsValid = false;
            }
          }
        }
      }
    });

    if (allItemsEmpty) {
      allOrdersValid = false;
    }

    // Update state with the validation result
    setIsDataChanged(allOrdersValid && allMedicareDetailsValid);
  }, [
    newOrder.orderItems,
    medicareExtraDetailsList,
    userData?.medicareExtraDetails,
    userData?.medicare,
    userData, // Add the parent userData object
  ]);

  const handleMedicareExtraChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const name = e.target.name;
      const value = e.target.value === 'yes' ? true : false;

      setUserData((prev) => ({
        ...prev,
        medicareExtraDetails: {
          ...prev?.medicareExtraDetails,
          [name]: value,
        },
      }));
    },
    [setUserData]
  );

  const extraMedicareFields = useMemo(
    () => (index: number) => {
      if (!medicareExtraDetailsList || !newOrder.orderItems[index].medicationName) return null;

      const extraFieldDetails = medicareExtraDetailsList.filter((med) =>
        med.medications.includes(newOrder.orderItems[index].medicationName.split(' (')[0])
      );
      if (extraFieldDetails.length === 0) return null;
      if (userData) {
        if (
          extraFieldDetails[0].id === 'donutHole' &&
          userData.medicare.partD === 'no' &&
          userData.medicare.advantage === 'no'
        )
          return null;

        if (extraFieldDetails[0].id === 'spent7.5' && userData.medicare.partD === 'no') return null;
      }

      // Check if this answer is valid
      const userAnswer =
        userData?.medicareExtraDetails && userData.medicareExtraDetails[`${extraFieldDetails[0].id}`] ? 'yes' : 'no';
      const isAnswerValid = userAnswer === extraFieldDetails[0].requiredAnswer;

      return (
        <Grid item xs={12}>
          <Stack spacing={2}>
            <FormControl>
              <FormLabel id={extraFieldDetails[0].id}>
                {extraFieldDetails[0].question}
                {!isAnswerValid && (
                  <Typography color="error" variant="caption" display="block" sx={{ mt: 1 }}>
                    * Please select the correct answer to proceed with your order.
                  </Typography>
                )}
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby={extraFieldDetails[0].id}
                value={
                  userData?.medicareExtraDetails && userData.medicareExtraDetails[`${extraFieldDetails[0].id}`]
                    ? 'yes'
                    : 'no'
                }
                name={extraFieldDetails[0].id}
                sx={{ mt: 2 }}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  handleMedicareExtraChange(e);
                  if (e.target.value !== extraFieldDetails[0].requiredAnswer) {
                    setOpenEligibilityDialog(true);
                  }
                }}
              >
                <FormControlLabel
                  value="no"
                  control={<Radio />}
                  label="No"
                  sx={{
                    pr: 2,
                    border: '1px solid #d9d9d9',
                    borderRadius: '4px',
                    backgroundColor: '#fff',
                    marginLeft: '0rem',
                    ...(userAnswer === 'no' &&
                      userAnswer !== extraFieldDetails[0].requiredAnswer && {
                        borderColor: 'error.main',
                      }),
                  }}
                />
                <FormControlLabel
                  value="yes"
                  control={<Radio />}
                  label="Yes"
                  sx={{
                    pr: 2,
                    border: '1px solid #d9d9d9',
                    borderRadius: '4px',
                    backgroundColor: '#fff',
                    marginLeft: '0rem',
                    ...(userAnswer === 'yes' &&
                      userAnswer !== extraFieldDetails[0].requiredAnswer && {
                        borderColor: 'error.main',
                      }),
                  }}
                />
              </RadioGroup>
            </FormControl>
          </Stack>
        </Grid>
      );
    },
    [
      handleMedicareExtraChange,
      medicareExtraDetailsList,
      newOrder.orderItems,
      userData?.medicare.advantage,
      userData?.medicare.partD,
      userData?.medicareExtraDetails,
    ]
  );

  async function sendOrder() {
    const filteredOrders = newOrder.orderItems.filter((item) => item.medicationName !== '');
    if (!userData) return;
    setIsSendingOrder(true);
    const userDocRef = doc(db, 'clients', userData.ssn.toString());
    const ordersRef = collection(userDocRef, 'orders');
    for (const orderItem of filteredOrders) {
      await addDoc(ordersRef, {
        medicationName: orderItem.medicationName,
        medicationStrength: orderItem.medicationStrength,
        doctorName: orderItem.doctorName,
        received: false,
        timestamp: new Date().toISOString(),
        faxSent: new Date().toISOString(),
      });
    }
    newOrder.pateintEmail = userData.email;
    newOrder.patientSSN = userData.ssn;

    await createOrder(newOrder);
    sendDataToEmail(newOrder);
    setIsSendingOrder(false);
    setIsOrderSent(true);
  }

  useEffect(() => {
    validateOrder();
  }, [newOrder.orderItems, validateOrder]);

  useEffect(() => {
    if (medicareExtraDetails && !loadingMedicareExtraDetails) {
      setMedicareExtraDetailsList(medicareExtraDetails.docs.map((doc) => doc.data()));
    }
  }, [loadingMedicareExtraDetails, medicareExtraDetails]);
  return (
    <>
      {isSendingOrder ? (
        <div className="loader-c">
          <div className="loader" />
        </div>
      ) : isOrderSent ? (
        <>
          <div className="success-c">
            <img src={ConfirmationIcon} alt="success" />
            <h2>Your order has been sent</h2>
          </div>
        </>
      ) : (
        <Stack className="container">
          <button
            className="back-btn"
            onClick={() => {
              navigateWithQuery(navigate, '/', location);
            }}
          >
            <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M13.6049 5.18512H2.85895L5.63995 7.96612L4.82095 8.78512L0.563945 4.52812L4.82095 0.27112L5.63995 1.09012L2.86795 3.86212H13.6049V5.18512Z"
                fill="#191919"
              />
            </svg>
            Back
          </button>

          <Typography variant="h1" textAlign={'center'} mb={6}>
            Order Medication
          </Typography>
          <Stack className="save-btn-c">
            <Button
              variant="contained"
              className="cta-main save-btn"
              disabled={!isDataChanged}
              onClick={() => {
                setOpenVerificationDialog(true);
              }}
            >
              Order Now
            </Button>
          </Stack>
          <Stack gap={2}>
            <Box className="tprx-card">
              <Stack>
                <Grid container spacing={2}>
                  {medicationNum > 0 &&
                    medicationList &&
                    Array.from({ length: medicationNum }, (_, index) => (
                      <React.Fragment key={index}>
                        <Grid item xs={12} sm={3}>
                          <TextField
                            required={isFirstTextFieldChanged[index]}
                            fullWidth
                            select
                            name={'doctorName'}
                            label={'Choose Doctor'}
                            onChange={(e) => {
                              handleDocChange(e as React.ChangeEvent<HTMLInputElement>, index);
                            }}
                            value={newOrder.orderItems[index].doctorName || ''}
                            variant="outlined"
                          >
                            {healthcareData?.map((doctor, i) => {
                              if (doctor.fname === undefined || doctor.fname === '') return;
                              return (
                                <MenuItem key={i} value={`${doctor.fname} ${doctor.lname}`}>
                                  {doctor.fname} {doctor.lname}
                                </MenuItem>
                              );
                            })}
                          </TextField>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <Autocomplete
                            filterOptions={filterMedicationsOptions}
                            disablePortal
                            options={medicationList}
                            freeSolo
                            forcePopupIcon={true}
                            value={
                              medicationList.find(
                                (option) => option.name === newOrder.orderItems[index].medicationName.split(' (')[0]
                              ) || null
                            }
                            getOptionLabel={(option: any) => (option.name + ` (${option.genericName})`) as string}
                            onChange={(_e, value) => {
                              handleMedicationChange(value, index);
                            }}
                            renderOption={(props, option: any) => {
                              const { key, ...optionProps } = props;
                              return (
                                <Stack key={key} component="li" {...optionProps} direction={'row'}>
                                  <Stack direction={'column'} mr={'auto'}>
                                    <Typography variant="body1">{option.name}</Typography>
                                    <Typography variant="body2">({option.genericName})</Typography>
                                  </Stack>
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      fontSize: '1.1rem',
                                      fontWeight: 600,
                                      backgroundColor: option.brand
                                        ? theme.palette.primary.dark
                                        : theme.palette.primary.main,
                                      color: theme.palette.common.white,
                                      padding: '0.2rem 0.5rem',
                                      borderRadius: '0.5rem',
                                      marginLeft: '2rem',
                                    }}
                                  >
                                    {option.brand ? 'Brand' : 'Generic'}
                                  </Typography>
                                </Stack>
                              );
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                required={index === 0}
                                fullWidth
                                name={'medicationName'}
                                label={'Select Medication'}
                                variant="outlined"
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <TextField
                            required={isFirstTextFieldChanged[index]}
                            key={`choosestrength-${index}`}
                            fullWidth
                            select
                            name={`medicationStrength`}
                            label={'Choose Medication Strength'}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                              handleStrengthChange(e, index);
                            }}
                            value={
                              medicationStrengths[index]?.some(
                                (option) => option.strength === newOrder.orderItems[index]['medicationStrength']
                              )
                                ? newOrder.orderItems[index]['medicationStrength']
                                : ''
                            }
                            variant="outlined"
                          >
                            {medicationStrengths[index] ? (
                              medicationStrengths[index]!.map((option) => (
                                <MenuItem key={option.strength} value={option.strength}>
                                  {option.strength}
                                </MenuItem>
                              ))
                            ) : (
                              <MenuItem value={''}>Choose Medication First</MenuItem>
                            )}
                          </TextField>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <Stack justifyContent={'center'} height={'100%'}>
                            <Typography fontWeight={600}>
                              {medCosts && medCosts[index] === 'Read Full Cost Estimation' ? (
                                <Link
                                  onClick={() => {
                                    handleClickOpen('cost');
                                  }}
                                  style={{ cursor: 'pointer' }}
                                >
                                  {medCosts[index]}
                                </Link>
                              ) : (
                                (medCosts && medCosts[index]) || 'Select Medication & Strength'
                              )}
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body2" color={'textSecondary'} fontWeight={500}>
                            {medicationDetailsText[index]}
                          </Typography>
                        </Grid>
                        {userData && userData.insurance.company === 'medicare' ? extraMedicareFields(index) : null}
                      </React.Fragment>
                    ))}
                </Grid>
                <Button
                  sx={{ marginRight: 'auto' }}
                  variant="outlined"
                  onClick={() => {
                    setMedicationNum((prev) => prev + 1);
                    setNewOrder((newOrder) => {
                      newOrder.orderItems.push({
                        doctorName: '',
                        medicationName: '',
                        medicationStrength: '',
                        docFax: '',
                        docFacility: '',
                      });
                      return newOrder;
                    });
                  }}
                >
                  Add Medication
                </Button>
              </Stack>
            </Box>
            <Box className="last-order-c tprx-card">
              <Typography variant="h2">My Orders</Typography>
              <Stack className="orders-list-dates">
                <Stack className="orders-list">
                  {Object.entries(groupedOrders).map(([date, orders]) => {
                    return (
                      <Stack className="orders-list-c" key={date}>
                        <Typography variant="h3">{date}</Typography>
                        {orders.map((order: DocumentData, index) => {
                          return (
                            <Stack className="order" key={index}>
                              <Stack className="order-info-row">
                                <Typography variant="body1">{order.medicationName}</Typography>
                                <Typography variant="body2">{order.medicationStrength}</Typography>
                              </Stack>
                            </Stack>
                          );
                        })}
                      </Stack>
                    );
                  })}
                </Stack>
              </Stack>
            </Box>
          </Stack>
          <QualificationDialog
            open={openEligibilityDialog}
            handleClose={handleCostDialogClose}
            qulificationGroup={'who are under 18'}
            isEligible={true}
            isMedicare={userData?.insurance.company === 'medicare'}
            medName={currentMedName.current || ''}
          />
          <VerificationDialog
            open={openVerificationDialog}
            handleClose={handleVerificationDialogClose}
            orderItems={newOrder.orderItems}
            sendOrder={sendOrder}
          />
        </Stack>
      )}
    </>
  );
}

export default Orders;
