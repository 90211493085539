/* eslint-disable no-mixed-spaces-and-tabs */
import { useContext, useEffect, useRef, useState } from 'react';
import {
  userDataState,
  ordersDataState,
  healthcareDataState,
} from '../../main';
import './ComponenetsStyles/PersonalArea.css';
import personalIcon from '../../assets/images/personal.svg';
import healthcareIcon from '../../assets/images/healthcare.svg';
import pharmaIcon from '../../assets/images/pharma.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  DocumentData,
  collection,
  doc,
  getDocs,
  query,
  where,
  updateDoc,
} from 'firebase/firestore'; // Add 'set' import
import { db } from '../../firebase-setup/firebase';
import { navigateWithQuery } from '../../utils/utilFuntions';
import { Stack } from '@mui/material';

function createStripePortal(uniqueId: string) {
  fetch('https://stripeportal-qqjaqi7y5q-uc.a.run.app', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ uniqueId }),
  })
    .then((response) => response.json())
    .then((data) => {
      window.location.href = data.url;
    });
}

function PersonalArea({
  medicationList = [],
}: {
  medicationList: DocumentData[] | null;
}) {
  const nameref = useRef<HTMLSpanElement>(null);
  const { userData } = useContext(userDataState);
  sessionStorage.setItem('userData', JSON.stringify(userData));
  const { ordersData } = useContext(ordersDataState);
  const { healthcareData } = useContext(healthcareDataState);
  const navigate = useNavigate();
  const location = useLocation();
  const { setOrdersData } = useContext(ordersDataState);
  const [orederReceived, setOrderReceived] = useState(false);
  const lastOrderDate = useRef<string>('');
  function getMedType(medName: string) {
    const med = medicationList?.find((med) => {
      return med.name === medName;
    });
    if (med) {
      <div className="home-med-type">Brand Medicaiton</div>;
    }
    return '';
  }
  async function medicationRecieved(order: DocumentData) {
    if (!userData) return;
    // Update the order to received
    const userDocRef = doc(db, 'clients', userData.ssn.toString());
    const ordersRef = collection(userDocRef, 'orders');
    const q = query(
      ordersRef,
      where('timestamp', '==', order.timestamp) &&
        where('medicationName', '==', order.medicationName)
    );
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      const docToUpdate = querySnapshot.docs[0];
      await updateDoc(doc(ordersRef, docToUpdate.id), {
        received: true,
      });
    }
    const ordersref = collection(userDocRef, 'orders');
    getDocs(ordersref).then((querySnapshot) => {
      const ordersData: DocumentData[] = [];
      querySnapshot.forEach((doc) => {
        ordersData.push(doc.data());
      });
      setOrdersData(ordersData);
    });
  }

  useEffect(() => {
    if (ordersData && userData && !userData.noMeds) {
      lastOrderDate.current = ordersData!
        .sort((a, b) => {
          return a.timestamp > b.timestamp ? -1 : 1;
        })[0]
        .timestamp.split('T')[0];
      const orderReceived = ordersData.some((order) => {
        return order.received === false;
      });
      setOrderReceived(orderReceived);
    }
  }, [ordersData, userData]);

  return (
    <>
      {userData && ordersData && healthcareData && (
        <div className="container">
          <div className=" margin-bottom-64">
            <h1 className="text-center">
              Welcome Back <span ref={nameref}>{userData.fname}</span>
            </h1>
            {healthcareData.length === 0 && (
              <h2 className="text-center">
                Please add your healthcare provider information to order
                medications.
              </h2>
            )}
            {ordersData.length === 0 && healthcareData.length > 0 && (
              <h2 className="text-center">
                Feel free to order your medications from the Orders page
              </h2>
            )}
          </div>
          <div className="personal-area-c">
            <div className="personal-area stretch">
              {orederReceived && !userData.noMeds ? (
                <>
                  <div className="tprx-card flexGrow">
                    <>
                      <h2>
                        <strong>
                          Your Recent Order
                          {` (${
                            ordersData!
                              .sort((a, b) => {
                                return a.timestamp > b.timestamp ? -1 : 1;
                              })[0]
                              .timestamp.split('T')[0]
                          })`}
                        </strong>
                      </h2>
                      <div className="home-recent-orders">
                        {ordersData!.map((order) => {
                          return order.received ===
                            true ? null : order.timestamp.split('T')[0] ===
                            lastOrderDate.current ? (
                            <div
                              key={order.medicationName}
                              className="home-recent-order-c">
                              <div className="home-recent-order-med-c">
                                {getMedType(order.medicationName)}
                                <p className="home-recent-order-medname">
                                  {order.medicationName}
                                </p>
                              </div>
                              <button
                                onClick={() => {
                                  medicationRecieved(order);
                                }}
                                className="cta-main logout-btn"
                                style={{ marginLeft: 'auto' }}>
                                Received
                                <svg
                                  width="16"
                                  height="17"
                                  viewBox="0 0 16 17"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <rect
                                    x="0.5"
                                    y="1.05762"
                                    width="15"
                                    height="15"
                                    rx="7.5"
                                    stroke="white"
                                  />
                                  <path
                                    d="M6.40951 10.4806L4.68744 8.75851C4.59465 8.66572 4.4688 8.61359 4.33757 8.61359C4.20634 8.61359 4.08049 8.66572 3.9877 8.75851C3.8949 8.8513 3.84277 8.97716 3.84277 9.10838C3.84277 9.17336 3.85557 9.2377 3.88044 9.29773C3.9053 9.35777 3.94175 9.41231 3.9877 9.45826L6.06212 11.5327C6.25567 11.7262 6.56832 11.7262 6.76186 11.5327L12.0124 6.28211C12.1052 6.18931 12.1574 6.06346 12.1574 5.93223C12.1574 5.80101 12.1052 5.67515 12.0124 5.58236C11.9196 5.48957 11.7938 5.43744 11.6626 5.43744C11.5313 5.43744 11.4055 5.48957 11.3127 5.58236L6.40951 10.4806Z"
                                    fill="white"
                                  />
                                </svg>
                              </button>
                            </div>
                          ) : null;
                        })}
                      </div>
                    </>
                  </div>

                  <Stack spacing={2} flexGrow={1} width={'100%'}>
                    <div
                      className="tprx-card flexGrow"
                      style={{ gap: '1.6rem' }}>
                      <h2>
                        <strong>
                          Steps for Obtaining & Tracking your Medication:
                        </strong>
                      </h2>
                      <button
                        className="cta-main tracking-btn"
                        onClick={() => {
                          navigateWithQuery(
                            navigate,
                            '/brandTracking',
                            location
                          );
                        }}>
                        Medication Tracking
                        <svg
                          width="32"
                          height="33"
                          viewBox="0 0 32 33"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M14.8871 13.3546V16.2924H11.7596V19.4199H8.8212V16.2924H5.69434V13.3546H8.8212V10.2271H11.7596V13.3546H14.8871Z"
                            stroke="#066890"
                            strokeWidth="1.07922"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M27.1996 26.3819H4.80039C3.35523 26.3819 2.17285 25.1994 2.17285 23.7543V9.36091C2.17285 7.91574 3.35523 6.73328 4.80039 6.73328H27.1996C28.6448 6.73328 29.8271 7.91574 29.8271 9.36091V23.7543C29.8271 25.1994 28.6448 26.3819 27.1996 26.3819Z"
                            stroke="#143A60"
                            strokeWidth="1.07922"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M23.2061 16.8051H26.2302M23.2061 19.8247H26.2302M23.2061 22.8443H26.2302"
                            stroke="#143A60"
                            strokeWidth="1.07922"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                    </div>
                    <div
                      className="tprx-card flexGrow"
                      style={{ gap: '1.6rem' }}>
                      <h2>
                        <strong>Update Payment Information</strong>
                      </h2>
                      <button
                        className="cta-main tracking-btn"
                        onClick={() => {
                          createStripePortal(userData.uniqueId);
                        }}>
                        Payment Center
                        <svg
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M4.46094 21.5965L21.5663 4.49121M24.8421 14.7405L21.9095 17.6731M19.4065 20.1462L18.1016 21.4511"
                            stroke="#076890"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                          />
                          <path
                            d="M4.23273 21.5218C2.14443 19.4335 2.14443 16.0477 4.23273 13.9594L13.9589 4.23322C16.0472 2.14492 19.433 2.14492 21.5213 4.23322L27.7669 10.4789C29.8553 12.5672 29.8553 15.953 27.7669 18.0413L18.0408 27.7674C15.9525 29.8558 12.5667 29.8558 10.4784 27.7674L4.23273 21.5218Z"
                            stroke="#076890"
                            strokeWidth="1.5"
                          />
                          <path
                            d="M5.3335 29.333H26.6668"
                            stroke="#153B61"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                          />
                        </svg>
                      </button>
                    </div>
                  </Stack>
                </>
              ) : null}
            </div>

            <div className="personal-area">
              <div
                className="info-button-box"
                onClick={() => {
                  navigateWithQuery(navigate, '/personalInfo', location);
                }}>
                <img src={personalIcon} alt="info" />
                <h2 className="text-center" style={{ maxWidth: '75%' }}>
                  Update Personal Information
                </h2>
              </div>
              <div
                className="info-button-box"
                onClick={() => {
                  navigateWithQuery(navigate, '/HealthcareProviders', location);
                }}>
                <img src={healthcareIcon} alt="info" />
                <h2 className="text-center">
                  Update Healthcare Provider Information
                </h2>
              </div>
              {healthcareData?.length !== 0 && (
                <div
                  className="info-button-box"
                  onClick={() => {
                    navigateWithQuery(navigate, '/orders', location);
                  }}>
                  <img src={pharmaIcon} alt="info" />
                  <h2 className="text-center" style={{ maxWidth: '75%' }}>
                    Order New Medications
                  </h2>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default PersonalArea;
