import { FaxFileEntry } from '../automation/types';

export async function scheduleTask(
  firstFaxFiles: FaxFileEntry,
  selectedClientJoinDate: number,
  selectClientName: string,
  selectClientId: string,
  type: string,
  automationOrder: number
) {
  const joinDate = new Date(selectedClientJoinDate);
  const tasks = [
    {
      filesPath: firstFaxFiles.files.map(
        (file) => `${selectClientName}/${file.name}`
      ),
      docFax: firstFaxFiles.docFax,
      docName: firstFaxFiles.docName,
      clientJoinDate: selectedClientJoinDate,
      clientId: selectClientId,
      sendDate: joinDate.getTime() + 2 * 24 * 60 * 60 * 1000,
      status: 'pending',
      type: type,
      automationOrder: automationOrder,
    },
  ];

  try {
    const response = await fetch(
      // 'https://faxsenderscheduler-qqjaqi7y5q-uc.a.run.app',
      'http://127.0.0.1:5001/transparent-rx/us-central1/faxSenderScheduler',
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ tasks }),
      }
    );

    if (!response.ok) {
      throw new Error('Failed to schedule batch tasks');
    }

    const data = await response.json();
    console.log('Batch tasks scheduled:', data);
  } catch (error) {
    console.error('Failed to schedule batch tasks:', error);
  }
}
