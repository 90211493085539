import { useEffect, useRef, useState } from 'react';
import './App.css';
import Navigation from './Componenets/client portal/Navigation';
import {
  userState,
  userDataState,
  healthcareDataState,
  ordersDataState,
  userPhoneNumberState,
} from './main';
import PersonalArea from './Componenets/client portal/PersonalArea';
import {
  Routes,
  Route,
  Outlet,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import PersonalInfo from './Componenets/client portal/PersonalInfo';
import { DocumentData, collection } from 'firebase/firestore';
import LoginPage from './Componenets/Login/LoginPage';
import LoginProvider from './Componenets/Login/LoginProvider';
import { User } from 'firebase/auth';
import HealthcareProviders from './Componenets/client portal/HealthcareProviders';
import Orders from './Componenets/client portal/orders/Orders';
import { useCollection } from 'react-firebase-hooks/firestore';
import { db } from './firebase-setup/firebase';
import { FAProvider } from './Componenets/Login/TwoFA';
import BrandTracking from './Componenets/client portal/BrandTracking';
import Intercom from '@intercom/messenger-js-sdk';
import SupportHub from './Componenets/client portal/SupportHub';
import ApplyForm from './Componenets/applicationForm/ApplyForm';
import InsurancePortal from './Componenets/insurance-portal/InsurancePortal';
import EligibilityCalculator from './Componenets/broker-portal/eligibilityCalculator';
import MedicationList from './Componenets/broker-portal/medicationList';
import Smartlook from 'smartlook-client';
import WelcomScreen from './Componenets/applicationForm/welcome/WelcomeScreen';
import ClientEligibility from './Componenets/marketingWebsite/ClientEligibility';
import MedicareCalculator from './Componenets/broker-portal/MedicareCalculator';
import FaxUploader from './Componenets/backOffice/automation/AutomationManager';
import AdminNav from './Componenets/backOffice/AdminNav';
import SMSHub from './Componenets/backOffice/SMSHub';
import HpFaxUpdating from './Componenets/client portal/HpFaxUpdating';
import ReEnrollmentScreen from './Componenets/client portal/ReEnrollment';
import ThankYou from './Componenets/client portal/ThankYou';

function App() {
  const [user, setUser] = useState<User | null>(null);
  const [userData, setUserData] = useState<DocumentData | null>(null);
  const [healthcareData, setHealthcareData] = useState<DocumentData[] | null>(
    null
  );
  const [ordersData, setOrdersData] = useState<DocumentData[] | null>(null);
  const [userPhoneNumber, setUserPhoneNumber] = useState<string | null>(null);
  const [medications, loadingMedications] = useCollection(
    collection(db, 'medications'),
    {
      snapshotListenOptions: { includeMetadataChanges: false },
    }
  );
  const medicationsList = useRef<DocumentData[] | null>(null);

  useEffect(() => {
    if (loadingMedications) return;
    medicationsList.current = medications!.docs.map((doc) => doc.data());
    medicationsList.current.sort((a, b) => {
      if (a.name > b.name) return 1;
      if (a.name < b.name) return -1;
      return 0;
    });
  }, [loadingMedications, medications]);

  useEffect(() => {
    if (!userData) return;
    Intercom({
      app_id: 'sr0dlb76',
      email: userData.email,
      name: userData.fname + ' ' + userData.lname,
    });
  }, [userData]);
  useEffect(() => {
    if (!Smartlook.initialized)
      Smartlook.init('378468602e6da2b4153d87acda40f4a3bf20cf23');
  }, []);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!location.search) return; // Only redirect if there are query params
    if (sessionStorage.getItem('redirect') !== null) {
      navigate(sessionStorage.getItem('redirect')!, { replace: true });
    } else {
      const queryParams = new URLSearchParams(location.search);
      const redirectPath = queryParams.get('redirect');
      if (redirectPath) {
        sessionStorage.setItem('redirect', redirectPath);
        navigate(redirectPath, { replace: true }); // Use replace to prevent history stacking
      }
    }
  }, [location.search, navigate]); // Only depend on user, so it runs once when the user is available

  return (
    <>
      <userState.Provider value={{ user, setUser }}>
        <userPhoneNumberState.Provider
          value={{ userPhoneNumber, setUserPhoneNumber }}>
          <FAProvider>
            <userDataState.Provider value={{ userData, setUserData }}>
              <healthcareDataState.Provider
                value={{ healthcareData, setHealthcareData }}>
                <ordersDataState.Provider value={{ ordersData, setOrdersData }}>
                  <Routes>
                    <Route
                      path={'/admin'}
                      element={
                        <AdminNav>
                          <Outlet />
                        </AdminNav>
                      }>
                      <Route
                        index
                        element={<h1>Welcome to Admin Dashboard</h1>}
                      />
                      <Route path="/admin/fax" element={<FaxUploader />} />
                      <Route path="/admin/sms" element={<SMSHub />} />
                    </Route>

                    <Route
                      path={''}
                      element={
                        <LoginProvider>
                          <Navigation>
                            <Outlet />
                          </Navigation>
                        </LoginProvider>
                      }>
                      <Route
                        index
                        element={
                          userData ? (
                            <PersonalArea
                              medicationList={medicationsList.current}
                            />
                          ) : (
                            <div className="loader-c">
                              <div className="loader" />
                            </div>
                          )
                        }
                      />
                      <Route
                        path="/personalInfo"
                        element={
                          userData ? (
                            <PersonalInfo />
                          ) : (
                            <div className="loader-c">
                              <div className="loader" />
                            </div>
                          )
                        }
                      />
                      <Route
                        path="/healthcareProviders"
                        element={healthcareData && <HealthcareProviders />}
                      />
                      <Route
                        path="/orders"
                        element={
                          ordersData && medications ? (
                            <Orders medicationList={medicationsList.current} />
                          ) : (
                            <div className="loader-c">
                              <div className="loader" />
                            </div>
                          )
                        }
                      />
                      <Route
                        path="/brandTracking"
                        element={
                          <BrandTracking
                            medicationList={medicationsList.current}
                            healthcareData={healthcareData}
                          />
                        }
                      />
                      <Route
                        path="/reEnrollment"
                        element={<ReEnrollmentScreen />}
                      />
                      <Route path="/SupportHub" element={<SupportHub />} />
                      <Route path={'/thank-you'} element={<ThankYou />} />
                    </Route>

                    <Route path={'/login'} element={<LoginPage />} />
                    <Route path={'/enroll'} element={<ApplyForm />} />
                    <Route path={'/welcome'} element={<WelcomScreen />} />
                    <Route
                      path={'/chc-dashboard'}
                      element={<InsurancePortal />}
                    />
                    <Route
                      path="/eligibility-calc"
                      element={<EligibilityCalculator />}
                    />
                    <Route path="/drug-lookup" element={<MedicationList />} />
                    <Route
                      path={'/calculator'}
                      element={<ClientEligibility />}
                    />
                    <Route
                      path={'/medicare-calc'}
                      element={<MedicareCalculator />}
                    />
                    <Route path={'/faxUpdating'} element={<HpFaxUpdating />} />
                  </Routes>
                </ordersDataState.Provider>
              </healthcareDataState.Provider>
            </userDataState.Provider>
          </FAProvider>
        </userPhoneNumberState.Provider>
      </userState.Provider>
    </>
  );
}

export default App;
