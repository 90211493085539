import { useNavigate } from 'react-router-dom';
import { auth, db } from '../../firebase-setup/firebase';
import { useIdToken } from 'react-firebase-hooks/auth';
import { useContext, useEffect, useRef, useState } from 'react';
import {
  healthcareDataState,
  ordersDataState,
  userDataState,
  userPhoneNumberState,
  userState,
} from '../../main';
import {
  doc,
  setDoc,
  collection,
  query,
  where,
  getDocs,
  DocumentData,
} from 'firebase/firestore';
import {
  User,
  deleteUser,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from 'firebase/auth';
import './Login.css';
import { useFA } from './TwoFA';
import TwoFAPage from './TwoFAPage';
import { Button, Stack } from '@mui/material';

const LoginProvider = ({
  children,
}: {
  children: JSX.Element[] | JSX.Element;
}) => {
  const navigate = useNavigate();
  const [userLogged, loading] = useIdToken(auth);
  const [checkingEmailLink, setCheckingEmailLink] = useState(true);
  const { setUser } = useContext(userState);
  const { setUserData } = useContext(userDataState);
  const { setHealthcareData } = useContext(healthcareDataState);
  const { setOrdersData } = useContext(ordersDataState);
  const { userPhoneNumber, setUserPhoneNumber } =
    useContext(userPhoneNumberState);
  const { isValidFactor } = useFA();
  const noExistingUser = useRef<boolean>(false);
  const isAdmin = useRef<boolean>(false);

  useEffect(() => {
    async function handleEmailLinkSignIn() {
      if (isSignInWithEmailLink(auth, window.location.href)) {
        let storedEmail = localStorage.getItem('emailForSignIn');

        if (!storedEmail) {
          storedEmail = prompt('Please enter your email for confirmation');
        }

        if (storedEmail) {
          try {
            await signInWithEmailLink(auth, storedEmail, window.location.href);
            localStorage.removeItem('emailForSignIn');
            navigate('/'); // Redirect after login
          } catch (error) {
            console.error('❌ Error signing in with email link:', error);
            navigate('/login');
          }
        }
      }
      setCheckingEmailLink(false); // ✅ Always set this to false
    }

    handleEmailLinkSignIn();
  }, [navigate]);

  useEffect(() => {
    function linkUserToFirestoreData(user: User) {
      // Assuming 'users' collection and email as unique identifier
      if (user.email!.includes('transparentpricerx.com')) {
        isAdmin.current = true;
        setDoc(
          doc(db, 'admin', user.uid),
          { email: user.email, admin: true },
          { merge: true }
        );
        navigate('/admin');
      } else {
        const usersRef = collection(db, 'clients');
        const q = query(usersRef, where('email', '==', user.email));
        getDocs(q).then((querySnapshot) => {
          if (!querySnapshot.empty) {
            // User data exists, link with Firebase Auth UID
            const userData = querySnapshot.docs[0];
            if (userData.data().isAccountCancled) {
              deleteUser(user)
                .then(() => {
                  navigate('/login');
                  // User deleted.
                  return;
                })
                .catch((error) => {
                  console.log(error);
                  // An error ocurred
                  // ...
                });
              return;
            }
            sessionStorage.setItem('userData', JSON.stringify(userData.data()));
            setUserPhoneNumber(
              userData.data().phone
                ? userData.data().phone.toString().replace(/\D/g, '')
                : userData.data().dayphone.toString().replace(/\D/g, '')
            );
            const userDocRef = doc(db, 'clients', userData.id);
            const healthcareref = collection(
              userDocRef,
              'healthcare-providers'
            );
            getDocs(healthcareref).then((querySnapshot) => {
              const healthcareData: DocumentData[] = [];
              querySnapshot.forEach((doc) => {
                healthcareData.push(doc.data());
              });
              if (
                healthcareData.length > 0 &&
                healthcareData[0].facility === ''
              ) {
                healthcareData.sort((a, b) => {
                  if (a.facility === '') return 0;
                  if (b.facility === '') return -1;
                  return a.facility.localeCompare(b.facility);
                });
              }

              setHealthcareData(healthcareData);
            });
            const ordersref = collection(userDocRef, 'orders');
            getDocs(ordersref).then((querySnapshot) => {
              const ordersData: DocumentData[] = [];
              querySnapshot.forEach((doc) => {
                ordersData.push(doc.data());
              });
              ordersData.sort((a, b) => {
                const dateA = new Date(a.orderDate);
                const dateB = new Date(b.orderDate);
                return dateA.getTime() - dateB.getTime();
              });
              setOrdersData(ordersData);
            });
            setUserData(userData.data());
            setDoc(userDocRef, { authUid: user.uid }, { merge: true });
          } else {
            // No existing user data, handle as new user
            // Create new document in Firestore, etc.
            noExistingUser.current = true;
          }
        });
        const redirectPath = sessionStorage.getItem('redirect');
        console.log('Redirect path:', redirectPath);
        if (redirectPath && isValidFactor) {
          sessionStorage.removeItem('redirect'); // Clear stored redirect
          navigate(redirectPath, { replace: true }); // Navigate to the stored path
        }
      }
    }
    if (!loading && !userLogged && !checkingEmailLink) {
      navigate('/login');
      return;
    } else {
      if (userLogged) {
        linkUserToFirestoreData(userLogged);
        setUser(userLogged);
        return;
      }
    }
  }, [
    loading,
    userLogged,
    navigate,
    setUserData,
    setUser,
    setHealthcareData,
    setOrdersData,
    setUserPhoneNumber,
    checkingEmailLink,
    isValidFactor,
  ]);

  if (noExistingUser.current) {
    return (
      <Stack
        alignContent={'center'}
        justifyContent={'center'}
        height={'calc(100vh - 6.4rem)'}>
        <Stack className="login-c" textAlign={'center'}>
          <h1>Account Not Found</h1>
          <p className="subtitle">
            Please use the same email address you used to register with in the
            application form
          </p>
          <Button
            variant="contained"
            className="cta-main"
            onClick={() => {
              const user = auth.currentUser;
              if (user) {
                deleteUser(user)
                  .then(() => {
                    navigate('/login');
                    // User deleted.
                  })
                  .catch((error) => {
                    console.log(error);
                    // An error ocurred
                    // ...
                  });
              }
              auth.signOut();
            }}>
            Back
          </Button>
        </Stack>
      </Stack>
    );
  } else if (userLogged && !isValidFactor && userPhoneNumber) {
    return <TwoFAPage />;
  } else {
    return (
      <>
        <div>{children}</div>
      </>
    );
  }
};

export default LoginProvider;
