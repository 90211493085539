/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  Divider,
  Stack,
  Typography,
  TextField,
  IconButton,
  Select,
  MenuItem,
  Button,
} from '@mui/material';
import useGooglePlaces from '../../utils/useGooglePlaces';
import dayjs from 'dayjs';
import { clientInfo, docInfo, formValues, orderInfo } from '../../utils/types';
import {
  useEffect,
  useState,
  useRef,
  memo,
  useCallback,
  useContext,
} from 'react';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { formatPhoneNumber, fplCalculator } from '../../utils/utilFuntions';
import { collection, doc, getDoc, getDocs, setDoc } from 'firebase/firestore';
import { db } from '../../firebase-setup/firebase';
import {
  userDataState,
  healthcareDataState,
  ordersDataState,
} from '../../main';
import { updateClient, updateDoctor } from '../../utils/sfFunctions';

const isMobile = window.innerWidth <= 771;

/* Formats field values for display based on field type */
const formatDisplayValue = (value: string, fieldName: string): string => {
  if (fieldName === 'income') {
    const numericValue = parseInt(value);
    if (!isNaN(numericValue)) {
      return '$' + numericValue.toLocaleString();
    }
  }
  return value;
};

// Memoized individual editable field component
const EditableField = memo(
  ({
    label,
    value,
    field,
    fieldType = 'input',
    validationType,
    options,
    saveType = 'patient',
    index = 0,
    onEdit,
    onSave,
    onCancel,
    editingField,
    formValues,
  }: {
    label: string;
    value: string;
    field: string;
    fieldType?: string;
    validationType?: string;
    options?: { value: string; label: string }[];
    saveType?: string;
    index?: number;
    onEdit: (field: string, section?: string) => void;
    onSave: (
      field: string,
      saveType: string,
      index: number,
      value: string,
      addressData?: any
    ) => void;
    onCancel: () => void;
    editingField: { section: string; field: string } | null;
    formValues?: formValues | null;
  }) => {
    const isEditing =
      editingField !== null &&
      editingField.section === saveType &&
      editingField.field === field;
    const inputRef = useRef<HTMLInputElement>(null);
    const [editValue, setEditValue] = useState<string>(value);
    const [error, setError] = useState<string>('');

    // Get the formatted display value
    const displayValue = formatDisplayValue(value, field);

    async function getMedication(medName: string) {
      const medicationsRef = collection(db, 'medications');
      const medications = await getDocs(medicationsRef);
      const orderdList = medications.docs.map((doc) => doc.data());
      const newMed = orderdList.find((med) => med.name === medName);
      return newMed;
    }

    async function newFplCalc(newValue: string, type: string) {
      const changedValue = Number(newValue);
      const newFpl =
        type === 'income'
          ? fplCalculator(Number(formValues?.patient.numinhouse), changedValue)
          : fplCalculator(changedValue, Number(formValues?.patient.income));
      const orders = formValues?.orders;
      let valid = true;

      if (orders && orders.length > 0) {
        for (const order of orders) {
          if (order.medicationName !== '') {
            const medName = order.medicationName.split('(')[0].trim();
            const medication = await getMedication(medName);

            if (medication?.fpl < newFpl) {
              valid = false;
              break;
            }
          }
        }
      }
      return valid;
    }

    // Field validation functions
    const fieldValidation = {
      name: (value: string) => {
        const nameRegex = /^[A-Za-zÀ-ÿ\s]+$/;
        const isNotEmpty = value.trim().length > 0;

        if (!isNotEmpty) {
          return {
            isValid: false,
            errorMessage: 'Name is required',
          };
        }
        if (!nameRegex.test(value)) {
          return {
            isValid: false,
            errorMessage: 'Name can only contain letters',
          };
        }
        return {
          isValid: true,
          errorMessage: '',
        };
      },
      address: (values: string) => {
        // For Google Places autocomplete, check if it's not empty
        const isNotEmpty = values.trim().length > 0;
        const addressRegex = /^[^,]+,\s*[^,]+,\s*[A-Z]{2},\s*USA$/;

        if (!isNotEmpty) {
          return {
            isValid: false,
            errorMessage: 'Address is required',
          };
        }
        if (!addressRegex.test(value)) {
          return {
            isValid: false,
            errorMessage: 'Enter valid address - (Address, city, state, USA)',
          };
        }
        return {
          isValid: true,
          errorMessage: '',
        };
      },
      email: (value: string) => {
        const emailRegex = /\S+@\S+\.\S+/;
        return {
          isValid: emailRegex.test(value),
          errorMessage: 'Please enter a valid email address',
        };
      },
      phone: (value: string) => {
        const phoneRegex = /^\(\d{3}\) \d{3} - \d{4}$/;
        return {
          isValid: phoneRegex.test(value),
          errorMessage: 'Please enter a valid phone number',
        };
      },

      zip: (value: string) => {
        const zipRegex = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
        return {
          isValid: zipRegex.test(value),
          errorMessage: 'Please enter a valid 5-digit ZIP code',
        };
      },
      income: async (value: string) => {
        const valid = await newFplCalc(value, 'income');

        return {
          isValid: valid,
          errorMessage: valid
            ? ''
            : 'This income is not eligiable for selected med',
        };
      },
      people: async (value: string) => {
        // Check if input contains only numbers
        if (!/^\d+$/.test(value)) {
          return {
            isValid: false,
            errorMessage: 'Please enter numbers only',
          };
        }

        // Check if input is within the 2-digit limit
        if (parseInt(value) > 99) {
          return {
            isValid: false,
            errorMessage: 'Maximum household size is 99',
          };
        }

        // After passing basic validation, check FPL eligibility
        const valid = await newFplCalc(value, 'people');

        return {
          isValid: valid,
          errorMessage: valid
            ? ''
            : 'This number of people is not eligiable for selected med',
        };
      },
      birthday: (value: string) => {
        const currentDate = dayjs();
        const newDiff = dayjs(formValues?.patient.dob).diff(
          currentDate,
          'year'
        );
        const previousDiff = dayjs(value).diff(currentDate, 'year');

        // Basic date format validation (MM/DD/YYYY)
        const dateRegex =
          /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;

        if (!value.match(dateRegex)) {
          return {
            isValid: false,
            errorMessage: 'Invalid date format (MM/DD/YYYY)',
          };
        }

        // Parse the date using dayjs
        const parsedDate = dayjs(value, 'MM/DD/YYYY', true);

        // Check if date is valid and within allowed range
        if (!parsedDate.isValid() || parsedDate.isAfter(dayjs())) {
          return {
            isValid: false,
            errorMessage:
              'Enter a valid date format (MM/DD/YYYY) between 1900 and today',
          };
        } else if (parsedDate.year() < 1900) {
          return {
            isValid: false,
            errorMessage: 'Date must be after 1900',
          };
        } else if (Math.abs(previousDiff) >= 65 && !(Math.abs(newDiff) >= 65)) {
          return {
            isValid: false,
            errorMessage: 'Age must remain above 65 for these conditions',
          };
        } else if (Math.abs(previousDiff) <= 65 && !(Math.abs(newDiff) <= 65)) {
          return {
            isValid: false,
            errorMessage: 'Age must remain under 65 for these conditions',
          };
        }

        return {
          isValid: true,
          errorMessage: '',
        };
      },
    };

    useEffect(() => {
      if (isEditing) {
        if (field === 'income') {
          // Format the income for display when editing starts
          const numericValue = parseInt(value);
          if (!isNaN(numericValue)) {
            setEditValue('$' + numericValue.toLocaleString());
          } else {
            setEditValue(value);
          }
        } else {
          setEditValue(value);
        }

        if (fieldType !== 'autocomplete' && inputRef.current) {
          inputRef.current.focus();
        }
      }
    }, [isEditing, value, fieldType, field]);

    const validateAndSave = async () => {
      let valueToValidate = editValue;
      let valueToSave = editValue;

      if (field === 'income') {
        // Extract only the numeric part for validation and saving
        valueToValidate = editValue.replace(/[^0-9]/g, '');
        valueToSave = valueToValidate; // Save the numeric value
      }

      if (validationType && validationType in fieldValidation) {
        try {
          // @ts-ignore
          const validation = await fieldValidation[validationType](
            valueToValidate
          );
          if (!validation.isValid) {
            setError(validation.errorMessage);
            return;
          }
        } catch (error) {
          console.error('Validation error:', error);
          setError('Error validating field');
          return;
        }
      }
      setError('');
      onSave(field, saveType, index, valueToSave);
    };

    const handleEditValueChange = (newValue: string) => {
      let formattedValue = newValue;

      if (
        field === 'phone' ||
        field === 'emerContactPhone' ||
        field === 'agentPhone'
      ) {
        formattedValue = formatPhoneNumber({
          target: { value: newValue },
        } as React.ChangeEvent<HTMLInputElement>);
      } else if (field === 'income') {
        // Remove non-numeric characters
        const numericValue = newValue.replace(/\D/g, '');

        if (numericValue === '') {
          setEditValue('');
          return;
        }

        // Format with dollar sign and commas
        formattedValue = '$' + parseInt(numericValue).toLocaleString();
      }

      setEditValue(formattedValue);
      if (error) setError('');
    };

    const handleSelectChange = (newValue: string) => {
      setEditValue(newValue);

      if (validationType && validationType in fieldValidation) {
        // @ts-ignore
        const validation = fieldValidation[validationType](newValue);
        if (!validation.isValid) {
          setError(validation.errorMessage);
          return;
        }
      }

      setError('');
      onSave(field, saveType, index, newValue);
    };

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}>
        <div
          style={{
            display: 'flex',
            alignItems: isMobile ? 'flex-start' : 'center',
            flexDirection: isMobile ? 'column' : 'row',
            justifyContent: 'space-between',
          }}>
          <Typography component="span" variant="body1" sx={{ fontWeight: 600 }}>
            {label}:
          </Typography>
          <span
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              position: 'relative',
              backgroundColor: 'white',
              border: '1px solid #cccccc',
              borderRadius: '0.5rem',
              padding: '0.5rem',
              width: isMobile ? '100%' : '400px',
              minHeight: '4rem',
            }}>
            {isEditing ? (
              fieldType === 'input' ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    gap: '8px',
                    position: 'relative',
                  }}>
                  <TextField
                    inputRef={inputRef}
                    value={editValue}
                    onChange={(e) => handleEditValueChange(e.target.value)}
                    size="small"
                    error={!!error}
                    sx={{
                      width: isMobile ? '100%' : '75%',
                      '& .MuiInputBase-input': {
                        border: 'none',
                        padding: 0,
                        width: '100%',
                      },
                      '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
                    }}
                  />
                  <Stack
                    alignItems="center"
                    position={'absolute'}
                    right={1}
                    direction={'row'}>
                    <IconButton onClick={onCancel} color="error" size="small">
                      <CloseIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      onClick={validateAndSave}
                      color="primary"
                      size="small">
                      <SaveIcon fontSize="small" />
                    </IconButton>
                  </Stack>
                </div>
              ) : fieldType === 'autocomplete' ? (
                <AutocompleteField
                  value={value}
                  onSave={onSave}
                  onCancel={onCancel}
                  field={field}
                  saveType={saveType}
                  index={index}
                  error={error}
                  setError={setError}
                />
              ) : (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}>
                  <Select
                    value={editValue}
                    onChange={(e) =>
                      handleSelectChange(e.target.value as string)
                    }
                    onClose={() => {
                      onCancel();
                    }}
                    size="small"
                    sx={{
                      width: '100%',
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                        padding: 0,
                      },
                      '& .MuiSelect-nativeInput': {
                        border: 'none',
                        padding: 0,
                      },
                      '& .MuiSelect-select': { border: 'none', padding: 0 },
                    }}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                    }}>
                    {options?.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              )
            ) : (
              <>
                <Typography component="span" variant="body1" maxWidth={'75%'}>
                  {displayValue}
                </Typography>
                <Button
                  variant="outlined"
                  onClick={() => onEdit(field, saveType)}
                  size="small"
                  sx={{
                    position: 'absolute',
                    right: '0rem',
                    border: 'unset',
                    padding: '0',
                    gap: '0.5rem',
                    borderRadius: '0 0.5rem 0.5rem 0',
                    height: '100%',
                  }}>
                  Edit
                  <EditIcon fontSize="small" />
                </Button>
              </>
            )}
          </span>
        </div>
        {isEditing && error && (
          <Typography
            variant="caption"
            color="error"
            sx={{
              mt: 0.5,
              ml: isMobile ? 0 : 'auto',
              mr: 0,
              textAlign: 'left',
            }}>
            {error}
          </Typography>
        )}
      </div>
    );
  }
);

// Autocomplete Field Component with updated error display
const AutocompleteField = memo(
  ({
    value,
    onSave,
    onCancel,
    field,
    saveType,
    index,
    error,
    setError,
  }: {
    value: string;
    onSave: (
      field: string,
      saveType: string,
      index: number,
      value: string,
      addressData?: any
    ) => void;
    onCancel: () => void;
    field: string;
    saveType: string;
    index: number;
    error: string;
    setError: (error: string) => void;
  }) => {
    const [inputValue, setInputValue] = useState(value || '');

    // Only initialize Google Places when this component is actually mounted
    const {
      ref: autocompleteRef,
      fullAddress,
      setFullAddress,
    } = useGooglePlaces((place) => {
      const addressComponents = place.address_components;
      const formattedAddress = place.formatted_address;

      // Create an object to store address components
      const updatedValues: any = {};

      // Exit if no address components are found
      if (!addressComponents) return;

      // Extract address components
      addressComponents.forEach(
        (component: {
          types: string[];
          long_name: string;
          short_name: string;
        }) => {
          const componentType = component.types[0];
          switch (componentType) {
            case 'street_number': {
              updatedValues.address =
                component.long_name !== 'undefined'
                  ? component.long_name
                  : component.short_name;
              break;
            }
            case 'route': {
              updatedValues.address =
                (updatedValues.address || '') + ' ' + component.short_name;
              break;
            }
            case 'locality':
              updatedValues.city = component.long_name;
              break;
            case 'administrative_area_level_1': {
              updatedValues.state = component.short_name;
              break;
            }
          }
        }
      );

      // Set the full formatted address
      updatedValues.fullAddress = formattedAddress;

      // Clear any errors
      setError('');

      // Save the address data with both the full address and components
      onSave(field, saveType, index, formattedAddress, updatedValues);
    }, value);

    // Initialize fullAddress when component mounts
    useEffect(() => {
      setFullAddress(value || '');
      setInputValue(value || '');
    }, [value, setFullAddress]);

    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          gap: '8px',
          position: 'relative',
        }}>
        <TextField
          inputRef={autocompleteRef}
          value={fullAddress}
          onChange={(e) => {
            setFullAddress(e.target.value);
            setInputValue(e.target.value);
            if (error) setError('');
          }}
          size="small"
          error={!!error}
          sx={{
            width: '80%',
            minWidth: isMobile ? '230px' : '250px',
            '& .MuiInputBase-input': {
              border: 'none',
              padding: 0,
              width: '80%',
            },
            '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
          }}
        />
        <Stack
          alignItems="center"
          position={'absolute'}
          right={1}
          direction={'row'}>
          <IconButton onClick={onCancel} color="error" size="small">
            <CloseIcon fontSize="small" />
          </IconButton>
          <IconButton
            onClick={() => {
              // Manual save for when user types an address but doesn't select from dropdown
              if (error) {
                const addressValid = inputValue.trim().length > 0;
                if (!addressValid) {
                  setError('Please enter a valid address');
                  return;
                }
              }
              onSave(field, saveType, index, fullAddress, { fullAddress });
            }}
            color="primary"
            size="small">
            <SaveIcon fontSize="small" />
          </IconButton>
        </Stack>
      </div>
    );
  }
);

function ReEnrollmentScreen() {
  const [validStep, setValidStep] = useState(true);
  const { userData } = useContext(userDataState);
  const { healthcareData } = useContext(healthcareDataState);
  const { ordersData } = useContext(ordersDataState);
  const [isUpdating, setIsUpdating] = useState(false);

  // State to track which field is being edited with section context
  const [values, setValues] = useState<formValues | null>(null);
  const [editingField, setEditingField] = useState<{
    section: string;
    field: string;
  } | null>(null);

  useEffect(() => {
    if (userData && healthcareData && ordersData && !values) {
      setValues({
        patient: userData as clientInfo,
        doctors: healthcareData as docInfo[],
        orders: ordersData as orderInfo[],
        agreements: {
          informationStatement: true,
          policiesStatement: true,
          incomeStatement: true,
          effectiveStatement: true,
        },
      });
    }
  }, [userData, healthcareData, ordersData, values]);

  // Handle starting edit mode for a field (memoized)
  const handleEdit = useCallback(
    (field: string, section: string = 'patient') => {
      setValidStep(false);
      setEditingField({ section, field });
    },
    []
  );

  // Handle saving edited value (memoized)
  const handleSave = useCallback(
    (
      field: string,
      saveType: string,
      index: number,
      newValue: string,
      addressData?: any
    ) => {
      setValues((prevValues) => {
        const updatedValues = { ...prevValues } as formValues;

        if (saveType === 'patient') {
          // Special handling for address fields with Google Places data
          if (field === 'fullAddress' && addressData) {
            // Store all address components
            updatedValues.patient.fullAddress = addressData.fullAddress;
            updatedValues.patient.address = addressData.address || '';
            updatedValues.patient.city = addressData.city || '';
            updatedValues.patient.state = addressData.state || '';
          }
          // Regular handling for manual address entry (no addressData)
          else if (field === 'fullAddress') {
            updatedValues.patient.fullAddress = newValue;
          }
          // Handle nested fields
          else if (field.includes('.')) {
            const [section, subfield] = field.split('.');
            //@ts-ignore
            updatedValues.patient[section][subfield] = newValue;
          }
          // Handle other regular fields
          else {
            //@ts-ignore
            updatedValues.patient[field] = newValue;
          }
        } else if (saveType === 'doc') {
          // Special handling for doctor address fields with Google Places data
          if (field === 'fullAddress' && addressData) {
            updatedValues.doctors[index].fullAddress = addressData.fullAddress;
            updatedValues.doctors[index].address = addressData.address || '';
            updatedValues.doctors[index].city = addressData.city || '';
            updatedValues.doctors[index].state = addressData.state || '';
          }
          // Regular handling for manual address entry (no addressData)
          else if (field === 'fullAddress') {
            updatedValues.doctors[index].fullAddress = newValue;
          }
          // Handle other doctor fields
          else {
            (updatedValues.doctors[index] as any)[field] = newValue;
          }
        } else if (saveType === 'agent') {
          (updatedValues.patient.agentInfo as Record<string, any>)[field] =
            newValue;
        }

        return updatedValues;
      });
      setValidStep(true);
      setEditingField(null);
    },
    [setValues]
  );
  const redirectToStripe = async (uniqueId: string) => {
    const response = await fetch(
      'https://stripereenroll-qqjaqi7y5q-uc.a.run.app',
      {
        method: 'POST',
        body: JSON.stringify({ uniqueId }),
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    const data = await response.json();
    // Assuming the response contains a URL for Stripe Checkout
    window.location.href = data.url;
  };
  // Handle canceling edit (memoized)
  const handleCancel = useCallback(() => {
    setValidStep(true);
    setEditingField(null);
  }, []);

  const handleSubmit = useCallback(async () => {
    if (!values || !userData || !healthcareData) return;
    setIsUpdating(true);
    // Function to check if two objects are equal
    const objectsAreEqual = (obj1: any, obj2: any) =>
      JSON.stringify(obj1) === JSON.stringify(obj2);

    // Check if patient data has changed
    const patientChanged = !objectsAreEqual(values.patient, userData);
    const doctorsChanged =
      values.doctors.length !== healthcareData.length ||
      values.doctors.some(
        (doc, index) => !objectsAreEqual(doc, healthcareData[index])
      );
    const salesforcePatientData = {
      fname: values.patient.fname,
      lname: values.patient.lname,
      mname: values.patient.mname,
      address: values.patient.address,
      city: values.patient.city,
      state: values.patient.state,
      zip: values.patient.zip,
      country: 'USA',
      phone: values.patient.phone,
      email: values.patient.email,
      marital: values.patient.marital,
      employed: values.patient.employed,
      sex: values.patient.sex,
      numinhouse: values.patient.numinhouse,
      emerContactName: values.patient.emerContactName,
      emerContactPhone: values.patient.emerContactPhone,
      income: parseInt(values.patient.income.toString().replace(/[^0-9]/g, '')),
    };
    const userDocRef = doc(db, 'clients', userData.ssn.toString());
    const userSnapshot = await getDoc(userDocRef);
    if (!userSnapshot.exists()) {
      return;
    }
    const userDocUniqueId = userSnapshot.data().uniqueId;
    if (doctorsChanged) {
      for (const doc of values.doctors) {
        await updateDoctor(doc);
      }
    }

    // Update patient data only if changes were detected
    if (patientChanged) {
      await updateClient(salesforcePatientData);
      await setDoc(userDocRef, values.patient, { merge: true });
    }

    await redirectToStripe(userDocUniqueId);
  }, [healthcareData, userData, values]);

  // Personal details section - memoized component
  const PersonalDetailsSection = memo(
    ({
      patient,
      onEdit,
      onSave,
      onCancel,
      editingField,
    }: {
      patient: any;
      onEdit: (field: string, section?: string) => void;
      onSave: (
        field: string,
        saveType: string,
        index: number,
        value: string
      ) => void;
      onCancel: () => void;
      editingField: { section: string; field: string } | null;
    }) => {
      // These options are static and won't change between renders
      const genderOptions = [
        { value: 'Male', label: 'Male' },
        { value: 'Female', label: 'Female' },
      ];

      const maritalOptions = [
        { value: 'Single', label: 'Single' },
        { value: 'Married', label: 'Married' },
        { value: 'Divorced', label: 'Divorced' },
        { value: 'Widowed', label: 'Widowed' },
        { value: 'Legally Separated', label: 'Legally Separated' },
        { value: 'Civil Union', label: 'Civil Union' },
      ];

      const employmentOptions = [
        { value: 'Full time', label: 'Full time' },
        { value: 'Part time', label: 'Part time' },
        { value: 'Self-Employed', label: 'Self-Employed' },
        { value: 'On Unemployment Checks', label: 'On Unemployment Checks' },
        { value: 'No Income in Household', label: 'No Income in Household' },
        { value: 'Contract', label: 'Contract' },
        { value: 'Seasonal', label: 'Seasonal' },
        { value: 'Retired', label: 'Retired' },
      ];

      return (
        values?.patient && (
          <>
            <EditableField
              label="First Name"
              value={patient.fname}
              field="fname"
              validationType="name"
              onEdit={onEdit}
              onSave={onSave}
              onCancel={onCancel}
              editingField={editingField}
            />
            <EditableField
              label="Middle Name"
              value={patient.mname}
              field="mname"
              validationType="name"
              onEdit={onEdit}
              onSave={onSave}
              onCancel={onCancel}
              editingField={editingField}
            />
            <EditableField
              label="Last Name"
              value={patient.lname}
              field="lname"
              validationType="name"
              onEdit={onEdit}
              onSave={onSave}
              onCancel={onCancel}
              editingField={editingField}
            />
            <EditableField
              label="Birthday"
              value={patient.dob}
              field="dob"
              onEdit={onEdit}
              onSave={onSave}
              onCancel={onCancel}
              editingField={editingField}
              validationType="birthday"
              formValues={values}
            />
            <EditableField
              label="Gender"
              value={patient.sex}
              field="sex"
              fieldType="select"
              options={genderOptions}
              onEdit={onEdit}
              onSave={onSave}
              onCancel={onCancel}
              editingField={editingField}
            />

            <EditableField
              label="Marital Status"
              value={patient.marital}
              field="marital"
              fieldType="select"
              options={maritalOptions}
              onEdit={onEdit}
              onSave={onSave}
              onCancel={onCancel}
              editingField={editingField}
            />
            <EditableField
              label="Employment Status"
              value={patient.employed}
              field="employed"
              fieldType="select"
              options={employmentOptions}
              onEdit={onEdit}
              onSave={onSave}
              onCancel={onCancel}
              editingField={editingField}
            />
            <EditableField
              label="Annual Income"
              value={
                typeof patient.income === 'number' && patient.income > 0
                  ? '$' + patient.income.toLocaleString()
                  : (patient.income as string) || ''
              }
              field="income"
              validationType="income"
              onEdit={onEdit}
              onSave={onSave}
              onCancel={onCancel}
              editingField={editingField}
              formValues={values}
            />

            <EditableField
              label="Phone Number"
              value={patient.phone}
              field="phone"
              validationType="phone"
              onEdit={onEdit}
              onSave={onSave}
              onCancel={onCancel}
              editingField={editingField}
            />
            <EditableField
              label="Number of people in household"
              value={patient.numinhouse}
              field="numinhouse"
              validationType="people"
              onEdit={onEdit}
              onSave={onSave}
              onCancel={onCancel}
              editingField={editingField}
              formValues={values}
            />
            <EditableField
              label="Address"
              value={patient.fullAddress}
              field="fullAddress"
              fieldType="autocomplete"
              validationType="address"
              onEdit={onEdit}
              onSave={onSave}
              onCancel={onCancel}
              editingField={editingField}
            />
            <EditableField
              label="Apartment/Suite"
              value={patient.apt}
              field="apt"
              onEdit={onEdit}
              onSave={onSave}
              onCancel={onCancel}
              editingField={editingField}
            />
            <EditableField
              label="ZIP Code"
              value={patient.zip}
              field="zip"
              validationType="zip"
              onEdit={onEdit}
              onSave={onSave}
              onCancel={onCancel}
              editingField={editingField}
            />
            <EditableField
              label="Alternate contact"
              value={patient.emerContactName}
              field="emerContactName"
              validationType="name"
              onEdit={onEdit}
              onSave={onSave}
              onCancel={onCancel}
              editingField={editingField}
            />
          </>
        )
      );
    }
  );

  return (
    userData &&
    healthcareData &&
    ordersData && (
      <Stack alignItems={'center'}>
        {isUpdating ? (
          <div className="loader-s">
            <Stack
              spacing={1}
              textAlign={'center'}
              justifyContent={'center'}
              alignItems={'center'}>
              <div className="loader" />
              <Typography variant="h2">Updating your information...</Typography>
            </Stack>
          </div>
        ) : (
          <Stack
            spacing={8}
            alignItems={'stretch'}
            sx={{
              width: {
                xs: '100%',
                md: '80rem',
              },
            }}>
            <Stack spacing={1} textAlign={'center'}>
              <Typography variant="h1" textAlign={'center'}>
                Please verify the information below
              </Typography>
              <Typography variant="h2" textAlign={'center'}>
                Accuracy is key to ensuring everything goes off without a hitch
              </Typography>
            </Stack>

            <Stack className="tprx-card" alignItems={'stretch'}>
              <Stack spacing={1} textAlign={'left'}>
                <Typography variant="h2">Personal Details</Typography>
                <Divider />
                <PersonalDetailsSection
                  patient={values?.patient}
                  onEdit={handleEdit}
                  onSave={handleSave}
                  onCancel={handleCancel}
                  editingField={editingField}
                />
              </Stack>

              {/* Healthcare providers section */}
              <Stack spacing={1} textAlign={'left'}>
                <Typography variant="h2">
                  Healthcare providers Details
                </Typography>
                {values?.doctors.map((doc, index) => (
                  <Stack spacing={1} textAlign={'left'} key={doc.fname + index}>
                    <Divider />
                    <EditableField
                      label="Name"
                      value={doc.fname}
                      field="fname"
                      validationType="name"
                      saveType="doc"
                      index={index}
                      onEdit={handleEdit}
                      onSave={handleSave}
                      onCancel={handleCancel}
                      editingField={editingField}
                    />
                    <EditableField
                      label="Middle name"
                      value={doc.mname}
                      field="mname"
                      validationType="name"
                      saveType="doc"
                      index={index}
                      onEdit={handleEdit}
                      onSave={handleSave}
                      onCancel={handleCancel}
                      editingField={editingField}
                    />
                    <EditableField
                      label="Last name"
                      value={doc.lname}
                      field="lname"
                      validationType="name"
                      saveType="doc"
                      index={index}
                      onEdit={handleEdit}
                      onSave={handleSave}
                      onCancel={handleCancel}
                      editingField={editingField}
                    />
                    <EditableField
                      label="Phone Number"
                      value={doc.phone}
                      field="phone"
                      validationType="phone"
                      saveType="doc"
                      index={index}
                      onEdit={handleEdit}
                      onSave={handleSave}
                      onCancel={handleCancel}
                      editingField={editingField}
                    />
                    <EditableField
                      label="Extension"
                      value={
                        doc.ext === '' || !doc.ext ? '' : doc.ext.toString()
                      }
                      field="ext"
                      saveType="doc"
                      index={index}
                      onEdit={handleEdit}
                      onSave={handleSave}
                      onCancel={handleCancel}
                      editingField={editingField}
                    />
                    <EditableField
                      label="Fax Number"
                      value={doc.fax}
                      field="fax"
                      validationType="phone"
                      saveType="doc"
                      index={index}
                      onEdit={handleEdit}
                      onSave={handleSave}
                      onCancel={handleCancel}
                      editingField={editingField}
                    />
                    <EditableField
                      label="Address"
                      value={doc.fullAddress}
                      field="fullAddress"
                      fieldType="autocomplete"
                      validationType="address"
                      saveType="doc"
                      index={index}
                      onEdit={handleEdit}
                      onSave={handleSave}
                      onCancel={handleCancel}
                      editingField={editingField}
                    />
                    <EditableField
                      label="Facility"
                      value={doc.facility}
                      field="facility"
                      saveType="doc"
                      index={index}
                      onEdit={handleEdit}
                      onSave={handleSave}
                      onCancel={handleCancel}
                      editingField={editingField}
                    />
                  </Stack>
                ))}
              </Stack>
            </Stack>
            <Stack spacing={1} justifyContent={'center'} alignItems={'center'}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={!validStep}>
                {!validStep
                  ? 'Make sure all fields are filled out correctly'
                  : 'The information above is correct'}
              </Button>
            </Stack>
          </Stack>
        )}
      </Stack>
    )
  );
}

export default ReEnrollmentScreen;
