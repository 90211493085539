import {
  Alert,
  Button,
  CircularProgress,
  Grid,
  Snackbar,
  Stack,
  Typography,
} from '@mui/material';
import ClientSelector from './ClientSelector';
import {
  collection,
  doc,
  DocumentData,
  setDoc,
  getDoc,
} from 'firebase/firestore';
import { useCallback, useEffect, useState } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { db, storage } from '../../../firebase-setup/firebase';
import AutomationTable from './AutomationTable';
import { ref, StorageReference, uploadBytes } from 'firebase/storage';
import {
  allFilesTpes,
  AllFilesType,
  FaxFileEntry,
  isFaxFileEntry,
} from './types';
import { scheduleTask } from '../hooks/scheduleTask';

function AutomationManager() {
  const [clientList, setClientList] = useState<string[]>([]);
  const [clientsData, loadingClientsData] = useCollection(
    collection(db, 'clients'),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );
  const [selectedClient, setSelectedClient] = useState<DocumentData | null>(
    null
  );
  const [clientFolder, setClientFolder] = useState<string | null>(null);

  const [uploadStatus, setUploadStatus] = useState<
    'success' | 'error' | 'loading' | null
  >(null);
  const [automationReady, setAutomationReady] = useState(false);
  const [automationFiles, setAutomationFiles] = useState<{
    clientFiles: {
      file: StorageReference;
      emailName: string;
      docName: string;
    }[];
    allFiles: AllFilesType;
  } | null>(null);
  const [isAutomationActive, setIsAutomationActive] = useState(false);
  const handleClientChange = useCallback(
    async (value: string) => {
      const clientDoc = clientsData!.docs.find((doc) => {
        const data = doc.data();
        return `${data.fname} ${data.lname} (${data.email})` === value;
      });
      if (clientDoc) {
        setSelectedClient(clientDoc.data());
      }
      const clientAutomationDocRef = doc(
        collection(db, 'automations'),
        clientDoc?.data().ssn
      );
      const clientAutomationDoc = await getDoc(clientAutomationDocRef);
      if (clientAutomationDoc.exists()) {
        setIsAutomationActive(clientAutomationDoc.data().status === 'active');
      }
    },
    [clientsData]
  );
  const uploadFile = useCallback(
    async (
      fileObject: FaxFileEntry | { files: File[] },
      emailName: string,
      clientName: string
    ) => {
      if (!automationFiles?.allFiles) return;

      // Determine whether the fileObject is a FaxFileEntry
      if (isFaxFileEntry(fileObject)) {
        const { files, docName, docFax } = fileObject;
        await Promise.all(
          files.map(async (file) => {
            const storageRef = ref(
              storage,
              `/clientFiles/${clientName}/${file.name}`
            );
            const metaData = {
              customMetadata: {
                emailName: emailName || '-',
                docName: docName || '-',
                docFax: docFax ? docFax.toString() : '-',
              },
            };

            try {
              await uploadBytes(storageRef, file, metaData);
            } catch (error) {
              console.error(`Failed to upload file: ${file.name}`, error);
              throw error; // Propagate the error
            }
          })
        );
      } else {
        const { files } = fileObject;
        await Promise.all(
          files.map(async (file) => {
            const storageRef = ref(
              storage,
              `/clientFiles/${clientName}/${file.name}`
            );
            const metaData = {
              customMetadata: {
                emailName: emailName || '-',
              },
            };

            try {
              await uploadBytes(storageRef, file, metaData);
            } catch (error) {
              console.error(`Failed to upload file: ${file.name}`, error);
              throw error;
            }
          })
        );
      }
    },
    [automationFiles?.allFiles]
  );

  const uploadAllFiles = useCallback(async () => {
    if (!automationFiles?.allFiles || !clientFolder) return;
    setUploadStatus('loading');
    try {
      await Promise.all(
        allFilesTpes.map(async (fileType) => {
          const files =
            automationFiles.allFiles[fileType.name as keyof AllFilesType];
          if (Array.isArray(files) && files.every(isFaxFileEntry)) {
            await Promise.all(
              files.map(async (fileObject) => {
                await uploadFile(fileObject, fileType.name, clientFolder);
              })
            );
          } else {
            await uploadFile(files, fileType.name, clientFolder);
          }
        })
      );
      setUploadStatus('success');
    } catch (error) {
      setUploadStatus('error');
    }
  }, [automationFiles?.allFiles, clientFolder, uploadFile]);
  const automationSetup = useCallback(async () => {
    if (!selectedClient || !automationFiles) return;
    const firstFaxFiles = automationFiles.allFiles.firstFax;
    const selectedClientJoinDate = selectedClient.timestamp.seconds * 1000;
    const selectClientName = selectedClient.fname + ' ' + selectedClient.lname;
    const selectClientId = selectedClient.ssn;
    await Promise.all(
      firstFaxFiles.map(async (fileObject) => {
        await scheduleTask(
          fileObject,
          selectedClientJoinDate,
          selectClientName,
          selectClientId,
          'firstFax',
          0
        );
      })
    );
    const hpInfo = firstFaxFiles.map((fileObject) => ({
      docFax: fileObject.docFax,
      docName: fileObject.docName,
      firstFaxStatus: 'scheduled',
    }));
    const docRef = doc(collection(db, 'automations'), selectClientId);
    await setDoc(docRef, {
      clientName: selectClientName,
      clientId: selectClientId,
      joinDate: selectedClientJoinDate,
      status: 'active',
      hps: hpInfo,
    });
  }, [automationFiles, selectedClient]);

  const handleStartAutomation = useCallback(async () => {
    if (!automationFiles) {
      console.error('No file data available for automation.');
      return;
    }
    await uploadAllFiles();
    await automationSetup();
  }, [automationFiles, automationSetup, uploadAllFiles]);

  useEffect(() => {
    if (!loadingClientsData && clientsData) {
      const orderedList = clientsData!.docs.map((doc) => {
        if (
          doc.data().insurance.company === 'medicare' ||
          doc.data().employed === 'No Income in Household'
        )
          return (
            doc.data().fname +
            ' ' +
            doc.data().lname +
            ' (' +
            doc.data().email +
            ')'
          );
      });

      orderedList.sort((a, b) => {
        if (a! > b!) return 1;
        if (a! < b!) return -1;
        return 0;
      });
      setClientList(
        orderedList.filter((client) => client !== undefined) as string[]
      );
    }
  }, [loadingClientsData, clientsData]);

  useEffect(() => {
    if (automationFiles) {
      const { allFiles } = automationFiles;
      const allLoaded =
        allFiles.welcomeEmail.files.length > 0 &&
        allFiles.firstFax.length > 0 &&
        allFiles.faxReminder.length > 0 &&
        allFiles.callManufactureFax.length > 0;

      if (allLoaded) {
        setAutomationReady(true);
      } else {
        setAutomationReady(false);
      }
    }
  }, [automationFiles]);

  const uploadStatusMessage = () => {
    return (
      <Snackbar
        open={!!uploadStatus}
        autoHideDuration={3000}
        onClose={() => setUploadStatus(null)} // Reset status on close
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <Alert
          onClose={() => setUploadStatus(null)}
          severity={
            uploadStatus === 'success'
              ? 'success'
              : uploadStatus === 'loading'
              ? 'info'
              : 'error'
          }
          sx={{ width: '100%' }}>
          {uploadStatus === 'success'
            ? 'All files uploaded successfully!'
            : uploadStatus === 'loading'
            ? 'Uploading files...'
            : 'Error uploading files. Please try again.'}
        </Alert>
      </Snackbar>
    );
  };

  return (
    <Stack className="container" sx={{ mt: 8 }}>
      {!loadingClientsData && clientsData ? (
        <>
          <Stack justifyContent={'center'} alignItems={'center'} gap={4}>
            <Typography variant="h2">
              Clients Files Uploader (Medicare & No Income)
            </Typography>
            <Stack className="tprx-card" width={'100%'}>
              <Grid container spacing={2} sx={{ fontSize: '1.2rem' }}>
                <ClientSelector
                  clientList={clientList}
                  selectedClient={selectedClient}
                  onClientChange={handleClientChange}
                />
                <Grid item sm={4}>
                  <Stack
                    spacing={3}
                    direction="row"
                    justifyContent={'flex-end'}
                    alignItems={'center'}>
                    <Button
                      size="small"
                      className="cta-main secondary-btn"
                      onClick={() => {
                        // if (row.isAutomationActive === 'active') {
                        //   onCancelAutomation(row.docFax);
                        // } else {
                        //   onUploadAllFiles(row.docFax);
                        // }
                      }}
                      disabled={!isAutomationActive || !selectedClient}>
                      <span style={{ fontSize: '1.2rem' }}>
                        Stop Automation
                      </span>
                    </Button>
                    <Button
                      size="small"
                      variant="contained"
                      onClick={handleStartAutomation}
                      disabled={
                        !automationReady ||
                        !selectedClient ||
                        isAutomationActive
                      }>
                      <span style={{ fontSize: '1.2rem' }}>
                        Start Automation
                      </span>
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
              <AutomationTable
                selectedClient={selectedClient}
                onFilesUpdate={setAutomationFiles}
                setClientFolder={setClientFolder}
              />
            </Stack>
          </Stack>
          {uploadStatusMessage()}
        </>
      ) : (
        <div className="loader-s">
          <CircularProgress />
        </div>
      )}
    </Stack>
  );
}
export default AutomationManager;
