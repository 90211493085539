import { Stack, Typography } from '@mui/material';
import { useCallback, useContext, useEffect } from 'react';
import { userDataState } from '../../main';
import { useLocation } from 'react-router-dom';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase-setup/firebase';

function ThankYou() {
  const { userData } = useContext(userDataState);
  const location = useLocation();

  const sendEmailandSms = useCallback(async () => {
    const { email, phone, ssn } = userData!;
    const payload = {
      email: email,
      phoneNumber: phone,
      ssn: ssn,
    };
    const response = await fetch(
      'https://reenrollmenthankyou-qqjaqi7y5q-uc.a.run.app',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      }
    );
    if (response.ok) {
      const userDocRef = doc(db, 'clients', userData!.ssn);

      await updateDoc(userDocRef, {
        requireReenrollment: false,
      }).catch((error) => {
        console.error('Error updating user data:', error);
      });
    } else {
      console.error('Error sending email');
    }
  }, [userData]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const successparams = params.get('success');

    if (userData && successparams) {
      console.log('Sending email and sms');
      sendEmailandSms();
    }
  }, [location.search, sendEmailandSms, userData]);

  return (
    <Stack
      className="container"
      gap={8}
      justifyContent={'center'}
      alignItems={'center'}>
      <Stack
        className="tprx-card"
        mt={8}
        textAlign={'center'}
        maxWidth={'70rem'}
        mx={'auto'}>
        <Stack gap={2}>
          <Typography variant="h1">Thank you!</Typography>
          <Typography variant="h3">
            We appreciate your continued trust in TransparentPriceRX.
            <br />
            Your re-enrollment allows us to keep providing you with reliable
            access to the medications you need.
          </Typography>
        </Stack>
        <Typography variant="body1">
          Thank you for choosing TransparentPriceRX—we look forward to serving
          you.
        </Typography>
      </Stack>
      <Typography variant="h2">Whats Next?</Typography>
      <Stack maxWidth={'50rem'} gap={4}>
        <Stack
          className="tprx-card"
          alignItems={'flex-start'}
          gap={2}
          position={'relative'}>
          <Typography variant="body2" position={'absolute'} top={16} right={16}>
            01/05
          </Typography>
          <img
            src="https://cdn.prod.website-files.com/64c1145cbf2b6e07020d3b41/65279423853065c9bd81f3a0_capsules.svg"
            alt="Thank you"
            width={48}
            height={48}
          />
          <Typography variant="h3">Medication Order</Typography>
          <Typography variant="body1">
            If your medication needs have changed, you can request the new
            medications through the client portal as usual. If your
            prescriptions remain the same, we will continue processing them
            without interruption.
          </Typography>
        </Stack>
        <Stack
          className="tprx-card"
          alignItems={'flex-start'}
          gap={2}
          position={'relative'}>
          <Typography variant="body2" position={'absolute'} top={16} right={16}>
            02/05
          </Typography>
          <img
            src="https://cdn.prod.website-files.com/64c1145cbf2b6e07020d3b41/651129d190b712e0108964b6_Welcome%20Email.svg"
            alt="Thank you"
            width={48}
            height={48}
          />
          <Typography variant="h3">Welcome Email</Typography>
          <Typography variant="body1">
            You will receive your TransparentpriceRX welcome email within 72
            Hours (three business days) Email. We've aligned ourselves with a
            U.S.-based nonprofit pharmacy to ensure a smooth enrollment for you.
          </Typography>
        </Stack>
        <Stack
          className="tprx-card"
          alignItems={'flex-start'}
          gap={2}
          position={'relative'}>
          <Typography variant="body2" position={'absolute'} top={16} right={16}>
            03/05
          </Typography>
          <img
            src="https://cdn.prod.website-files.com/64c1145cbf2b6e07020d3b41/651129d1cf6103bc77401909_Health%20Communication.svg"
            alt="Thank you"
            width={48}
            height={48}
          />
          <Typography variant="h3">
            Healthcare Provider Communication
          </Typography>
          <Typography variant="body1">
            You and your health care provider will receive timely reminders to
            ensure an uninterrupted supply of medications all year round.
          </Typography>
        </Stack>
        <Stack
          className="tprx-card"
          alignItems={'flex-start'}
          gap={2}
          position={'relative'}>
          <Typography variant="body2" position={'absolute'} top={16} right={16}>
            04/05
          </Typography>
          <img
            src="https://cdn.prod.website-files.com/64c1145cbf2b6e07020d3b41/651129d0a440e1caefc8f2a8_Medication%20Review.svg"
            alt="Thank you"
            width={48}
            height={48}
          />
          <Typography variant="h3">Medication Review</Typography>
          <Typography variant="body1">
            Your health care provider will review, approve, and sign all
            medication forms before sending them to the manufacturer's pharmacy.
          </Typography>
        </Stack>
        <Stack
          className="tprx-card"
          alignItems={'flex-start'}
          gap={2}
          position={'relative'}>
          <Typography variant="body2" position={'absolute'} top={16} right={16}>
            05/05
          </Typography>
          <img
            src="https://cdn.prod.website-files.com/64c1145cbf2b6e07020d3b41/651129d1bdc044295dd4fc3e_YearService.svg"
            alt="Thank you"
            width={48}
            height={48}
          />
          <Typography variant="h3">Year-Round Service</Typography>
          <Typography variant="body1">
            Expect timely refill reminders for you and your health care provider
            through our specialized software.
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default ThankYou;
