/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';

const useGooglePlaces = (onPlaceSelected: (place: any) => void, initialValue: string = '') => {
  const { ref } = usePlacesWidget({
    apiKey: import.meta.env.VITE_GOOGLE_KEY,
    options: {
      componentRestrictions: { country: 'us' },
      fields: ['address_components', 'formatted_address'],
      types: ['address'],
    },
    onPlaceSelected,
  });

  const [fullAddress, setFullAddress] = useState<string>(initialValue);

  return {
    ref,
    fullAddress,
    setFullAddress,
  };
};

export default useGooglePlaces;
