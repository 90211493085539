import {
  Button,
  Drawer,
  List,
  ListItem,
  Stack,
  Typography,
} from '@mui/material';
import welcomeImg from '../../../assets/images/welcome.webp';
import { useLocation, useNavigate } from 'react-router-dom';
import { navigateWithQuery } from '../../../utils/utilFuntions';
import { useState } from 'react';

function WelcomScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  return (
    <>
      <Stack
        justifyContent={{
          xs: 'flex-start',
          md: 'center',
        }}
        sx={{
          backgroundImage: 'linear-gradient( #89aeb765, var(--background) 35%)',
          height: 'calc(100vh - 6.4rem)',
        }}>
        <Stack
          className="container"
          sx={{
            paddingTop: {
              xs: 4,
              md: 8,
            },
          }}>
          <Stack
            spacing={{
              xs: 4,
              md: 12,
            }}
            direction={{
              xs: 'column',
              md: 'row',
            }}
            alignItems={'center'}>
            <Stack
              width={'100%'}
              spacing={{
                xs: 2,
                md: 4,
              }}>
              <Stack spacing={2}>
                <Typography
                  variant="h1"
                  fontSize={{
                    xs: '3rem',
                    md: '4rem',
                  }}>
                  Transparent and Seamless Medication Procurement
                </Typography>
                <Typography variant="h3" fontWeight={400} lineHeight={1.5}>
                  We provide efficient access to affordable and accessible
                  medications to all Americans who have been prescribed
                  medications they simply cannot afford.
                </Typography>
                <Typography variant="h3" fontWeight={400} lineHeight={1.5}>
                  We are working directly with you, your healthcare provider,
                  and the pharmaceutical manufacturers' pharmacy.
                </Typography>
              </Stack>
              <Stack
                direction={{
                  xs: 'column',
                  md: 'row',
                }}
                spacing={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    navigateWithQuery(navigate, '/enroll', location)
                  }>
                  Become a Member
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    setOpen(true);
                  }}>
                  Learn More
                </Button>
              </Stack>
            </Stack>
            <Stack
              width={'100%'}
              justifyContent={'center'}
              alignItems={'center'}>
              <img src={welcomeImg} alt="medications" width="500" />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        <Stack
          justifyContent={'center'}
          alignItems={'end'}
          p={{
            xs: 2,
            md: 6,
          }}
          spacing={2}
          sx={{
            width: {
              sm: '100%',
              md: '60vw',
            },
            position: 'relative',
          }}
          role="presentation">
          <Button onClick={toggleDrawer(false)} variant="contained">
            Close
          </Button>
          <Stack>
            <Typography variant="h2" mb={1}>
              Welcome to the TransparentPriceRx Pharmacy Program (TPRX)
            </Typography>
            <Typography variant="body1" mb={4}>
              TPRX offers Medicare recipients a streamlined approach to access
              prescribed brand-name medications at no cost for our members,
              shipped directly from the manufacturer. With TPRX, you no longer
              need to visit your pharmacy.
            </Typography>
            <Typography variant="h3" mb={1}>
              Enrollment Process:
            </Typography>
            <List
              sx={{
                backgroundColor: 'transparent',
                border: 'none',
                listStyle: 'disc',
              }}>
              <ListItem
                sx={{
                  display: 'list-item',
                }}>
                <Typography variant="body1">
                  <strong>Enroll:</strong> Sign up easily through our online
                  platform or with the help of your agent.
                </Typography>
              </ListItem>
              <ListItem
                sx={{
                  display: 'list-item',
                }}>
                <Typography variant="body1">
                  <strong>Next Steps Email: </strong> You will receive an email
                  titled "Next Steps" three business days after your enrollment.
                  Please follow the instructions provided to ensure a smooth
                  continuation of the process.
                </Typography>
              </ListItem>
              <ListItem
                sx={{
                  display: 'list-item',
                }}>
                <Typography variant="body1">
                  <strong> Medication Delivery:</strong> Expect your medications
                  to arrive directly from the manufacturer within 2 to 3 weeks
                  after your healthcare provider has faxed the necessary
                  information.
                </Typography>
              </ListItem>
              <ListItem
                sx={{
                  display: 'list-item',
                }}>
                <Typography variant="body1">
                  <strong> Support:</strong> Our team is available to assist
                  with any questions about your prescriptions and to help you
                  navigate the program.
                </Typography>
              </ListItem>
            </List>
            <Typography variant="h2" mb={1}>
              Important Information:
            </Typography>
            <List
              sx={{
                backgroundColor: 'transparent',
                border: 'none',
                listStyle: 'disc',
              }}>
              <ListItem
                sx={{
                  display: 'list-item',
                }}>
                <Typography variant="body1">
                  <strong>Healthcare Provider FAX Number:</strong> It is
                  essential to ensure the fax number for your healthcare
                  provider is correct in our system, as it is our only means of
                  communicating with them to process your prescriptions.
                </Typography>
              </ListItem>
              <ListItem
                sx={{
                  display: 'list-item',
                }}>
                <Typography variant="body1">
                  <strong>Income Verification:</strong> We only require your
                  social security checks or Social Security Yearly Benefits
                  Statement (SSA-1099) for income verification. No other sources
                  of income need to be included.
                </Typography>
              </ListItem>
              <ListItem
                sx={{
                  display: 'list-item',
                }}>
                <Typography variant="body1">
                  <strong>Address Verification: </strong> Please select your
                  address from the dropdown menu during enrollment; the system
                  requires this to proceed.
                </Typography>
              </ListItem>
            </List>
            <Typography variant="body1">
              Join TPRX for a hassle-free way to receive your necessary
              medications without the need to visit your pharmacy.
            </Typography>
          </Stack>
        </Stack>
      </Drawer>
    </>
  );
}
export default WelcomScreen;
