/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Autocomplete,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import formConfig from '../formConfig';
import DatePickerValue from './DateField';
import InsuranceFields from './InsuranceFields';
import MedicareFields from './MedicareFields';
import React, {
  useState,
  ChangeEvent,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { clientInfo, formConfigField, formValues } from '../../../utils/types';
import dayjs from 'dayjs';
import QualificationDialog from '../QualificationDialog';
import { collection, DocumentData } from 'firebase/firestore';
import theme from '../../../theme';
import { formatPhoneNumber, fplCalculator } from '../../../utils/utilFuntions';
import { useCollection } from 'react-firebase-hooks/firestore';
import { db } from '../../../firebase-setup/firebase';

const FirstStep = React.memo(
  ({
    isMedicare,
    setIsMedicare,
    values,
    setValues,
    setValidStep,
    insuranceDataList,
    isMedicareOnly,
  }: {
    isMedicare: boolean;
    setIsMedicare: (value: boolean) => void;
    values: formValues;
    setValues: React.Dispatch<React.SetStateAction<formValues>>;
    setValidStep: React.Dispatch<React.SetStateAction<boolean>>;
    insuranceDataList: DocumentData[] | null;
    isMedicareOnly: boolean;
  }) => {
    const [agentList, loadingAgentList] = useCollection(
      collection(db, 'agents'),
      {
        snapshotListenOptions: { includeMetadataChanges: false },
      }
    );
    const [agentDataList, setAgentDataList] = useState<DocumentData[] | null>(
      null
    );

    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [prevValues, setPrevValues] = useState(values);
    const [prevErrors, setPrevErrors] = useState(errors);

    const [incomeValue, setIncomeValue] = useState<string | null>(
      values.patient.income.toString() !== ''
        ? '$' + parseInt(values.patient.income.toString()).toLocaleString()
        : ''
    );
    const [isIncomeFocused, setIsIncomeFocused] = useState<boolean>(false);
    const [requiredInsurnaceFields, setRequiredInsurnaceFields] = useState<{
      [key: string]: boolean;
    }>({
      company: true,
      product: true,
      plan: true,
    });
    const [isMedicarePartD, setIsMedicarePartD] = useState<boolean>(
      values.patient.medicare.partD === 'yes'
    );
    const [isMedicarePartC, setIsMedicarePartC] = useState<boolean>(
      values.patient.medicare.advantage === 'yes'
    );
    const calculateAgeEligibility = useCallback(
      (dob: string, minMax: string, thresholdAge: number) => {
        const today = new Date();
        const dobDate = new Date(dob);

        if (isNaN(dobDate.getTime())) {
          return false;
        }
        // Calculate the age
        const diffYears = today.getFullYear() - dobDate.getFullYear();
        const diffMonths = today.getMonth() - dobDate.getMonth();
        const diffDays = today.getDate() - dobDate.getDate();
        if (minMax === 'min') {
          if (
            diffYears < thresholdAge ||
            (diffYears === thresholdAge && diffMonths < 0) ||
            (diffYears === thresholdAge && diffMonths === 0 && diffDays < 0)
          ) {
            return false;
          }
          return true;
        } else {
          if (diffYears > thresholdAge) {
            return false;
          } else if (diffYears === thresholdAge && diffMonths > 0) {
            return false;
          } else if (
            diffYears === thresholdAge &&
            diffMonths === 0 &&
            diffDays >= 0
          ) {
            return false;
          }
          return true;
        }
      },
      []
    );
    const [openAgeDialog, setOpenAgeDialog] = useState<boolean>(false);
    const [isSpouseOnSSC, setIsSpouseOnSSC] = useState<boolean>(
      values.patient.medicare.bothSSC === 'yes'
    );
    const [medicareFPLEligibility, setMedicareFPLEligibility] = useState(false);
    const [customDialogTitle, setCustomDialogTitle] = useState<string>('');
    const [customDialogSubtitle, setCustomDialogSubtitle] =
      useState<string>('');

    const dialogText = useRef<string>('who are under 18');
    const clientFPL = useRef<number>(0);
    const handleClose = () => {
      setOpenAgeDialog(false);
    };
    // check clinet FPL for Medicare Eligibility
    const medicareFPLEligibilityCheck = useCallback(() => {
      if (!isMedicare) return;

      if (
        parseInt(values.patient.numinhouse) > 1 &&
        values.patient.medicare.spouceRecieveSSC === 'yes'
      ) {
        clientFPL.current = fplCalculator(
          1,
          parseInt(values.patient.income as string)
        );
      } else {
        clientFPL.current = fplCalculator(
          parseInt(values.patient.numinhouse as string),
          parseInt(values.patient.income as string)
        );
      }
      if (clientFPL.current < 142) {
        if (
          values.patient.numinhouse === '1' ||
          (parseInt(values.patient.numinhouse) > 1 &&
            values.patient.medicare.bothSSC === 'yes') ||
          (parseInt(values.patient.numinhouse) > 1 &&
            values.patient.medicare.spouceRecieveSSC === 'yes')
        ) {
          setCustomDialogTitle(
            'Please ensure that the ANNUAL Household income (Only Social Security Checks income) is provided, not the monthly household income.'
          );
          setCustomDialogSubtitle(
            'Participants in the Low-Income Subsidy program do not qualify for TPRX'
          );
          setOpenAgeDialog(true);
          setValidStep(false);
          setMedicareFPLEligibility(false);
        }
      } else {
        setOpenAgeDialog(false);
        setCustomDialogSubtitle('');
        setCustomDialogTitle('');
        setMedicareFPLEligibility(true);
      }
    }, [
      isMedicare,
      setValidStep,
      values.patient.income,
      values.patient.medicare.bothSSC,
      values.patient.medicare.spouceRecieveSSC,
      values.patient.numinhouse,
    ]);
    const handleChangeBirthday = useCallback(
      (value: string) => {
        setValues((prevValues) => ({
          ...prevValues,
          patient: {
            ...prevValues.patient,
            dob: value,
          },
        }));
      },

      [setValues]
    );
    const handleAgentChange = useCallback(
      (value: string, isFromList: boolean, field: string) => {
        if (isFromList) {
          const npn = agentDataList?.find(
            (agent) => agent.agentName === value
          )?.npn;
          const agentName = agentDataList?.find(
            (agent) => agent.agentName === value
          )?.agentName;

          setValues((prevValues) => ({
            ...prevValues,
            patient: {
              ...prevValues.patient,
              agentInfo: {
                ...prevValues.patient.agentInfo,
                agentName: agentName,
                agentId: npn,
              },
            },
          }));
        } else {
          setValues((prevValues) => ({
            ...prevValues,
            patient: {
              ...prevValues.patient,
              agentInfo: {
                ...prevValues.patient.agentInfo,
                [field]: value,
              },
            },
          }));
        }
      },
      [agentDataList, setValues]
    );
    const requiredCheck = useCallback(() => {
      let hasErrors = false;
      const newErrors: { [key: string]: string } = { ...errors };
      const validateField = (field: formConfigField, value: any) => {
        if (field.required && value === '') {
          if (isMedicareOnly) {
            if (
              field.name === 'plan' ||
              field.name === 'product' ||
              field.name === 'company'
            ) {
              return;
            }
          } else if (field.name === 'disabled') {
            return;
          }
          newErrors[field.name] = 'This field is required';
          hasErrors = true;
        } else if (field.name === 'dob') {
          const dateRegex =
            /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;

          if (!value.match(dateRegex)) {
            newErrors[field.name] = 'Invalid date format (MM/DD/YYYY)';
            return;
          } else {
            const parsedDate = dayjs(value, 'MM/DD/YYYY', true);
            if (
              !parsedDate.isValid() ||
              parsedDate.year() < 1900 ||
              parsedDate.isAfter(dayjs())
            ) {
              newErrors[field.name] =
                'Enter a valid date (MM/DD/YYYY) between 1900 and today';
              return;
            }

            if (
              dayjs(value as string).isBetween(
                dayjs().subtract(18, 'year'),
                dayjs()
              )
            ) {
              newErrors[field.name] = 'You must be 18 years old or older';
              setOpenAgeDialog(true);
              hasErrors = true;
            } else if (
              isMedicareOnly &&
              dayjs(value as string).isBetween(
                dayjs().subtract(65, 'year'),
                dayjs()
              ) &&
              values.patient.disabled === 'no'
            ) {
              newErrors.dob =
                'You must be at least 65 years old or a person of disability to apply for medications under our Medicare program';
              hasErrors = true;
            } else if (
              !dayjs(value as string).isAfter(dayjs().subtract(65, 'year'))
            ) {
              newErrors[field.name] = '';
              setIsMedicare(true);
              return;
            } else {
              delete newErrors[field.name];
            }
          }
        } else if (field.name === 'agentPhone' && value !== '') {
          const re = /^\D?(\d{3})\D*?(\d{3})\D*?(\d{4})$/;
          if (!re.test(value) && value !== '') {
            newErrors[field.name] = 'Invalid phone number';
            hasErrors = true;
          } else {
            delete newErrors[field.name];
          }
        } else if (field.name === 'agentEmail' && value) {
          console.log(value);
          const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
          if (!re.test(value) && value !== '') {
            newErrors[field.name] = 'Invalid email';
            hasErrors = true;
          } else {
            delete newErrors[field.name];
          }
        } else {
          delete newErrors[field.name];
        }
      };

      formConfig[0].fields!.forEach((field) => {
        let value;
        if (field.name.includes('agent')) {
          value =
            values.patient.agentInfo[
              field.name as keyof typeof values.patient.agentInfo
            ];
        } else {
          value = values.patient[field.name as keyof typeof values.patient];
        }
        validateField(field, value);
      });

      // Validate Insurance Fields
      Object.keys(requiredInsurnaceFields).forEach((field) => {
        if (isMedicare || isMedicareOnly) {
          delete newErrors[field];
        } else if (
          requiredInsurnaceFields[field] &&
          !values.patient.insurance[
            field as keyof typeof values.patient.insurance
          ]
        ) {
          newErrors[field] = 'This field is required';
          hasErrors = true;
        } else {
          delete newErrors[field];
        }
      });

      // Validate Medicare Fields
      const allMedicareFieldsValid = formConfig[0].medicare!.every((field) => {
        if (!isIncomeFocused) {
          medicareFPLEligibilityCheck();
        }
        if (medicareFPLEligibility === false) return false;
        if (!field.required) return true;
        if (values.patient.numinhouse !== '1' && clientFPL.current > 142) {
          if (
            (field.name === 'bothSSC' &&
              values.patient.medicare.bothSSC === 'no' &&
              values.patient.medicare.spouceRecieveSSC !== 'yes') ||
            (field.name === 'spouceRecieveSSC' &&
              values.patient.medicare.bothSSC === 'no' &&
              values.patient.medicare.spouceRecieveSSC === 'no')
          ) {
            return false;
          } else if (
            field.name === 'bothSSC' &&
            values.patient.medicare.spouceRecieveSSC === 'yes'
          ) {
            return true;
          }
          if (
            field.name === 'spouceRecieveSSC' &&
            values.patient.medicare.bothSSC === 'yes'
          ) {
            return true;
          }
        } else if (clientFPL.current < 142) {
          console.log('clientFPL.current', clientFPL.current);
          return false;
        } else if (
          clientFPL.current > 150 &&
          values.patient.numinhouse === '1'
        ) {
          return true;
        }

        if (!isMedicarePartD) {
          if (isMedicarePartC && field.name === 'advantageCoverage') {
            return !!values.patient.medicare.advantageCoverage;
          }
          if (['partD', 'advantage', 'supplement'].includes(field.name)) {
            return !!values.patient.medicare[
              field.name as keyof typeof values.patient.medicare
            ];
          }
          return true;
        }

        return !!values.patient.medicare[
          field.name as keyof typeof values.patient.medicare
        ];
      });

      const allRequiredFieldsValid = formConfig[0].fields!.every(
        (field) =>
          !field.required ||
          !!values.patient[field.name as keyof typeof values.patient]
      );

      const allRequiredInsuranceFieldsValid = Object.keys(
        requiredInsurnaceFields
      ).every((field) => {
        if (isMedicareOnly || isMedicare) {
          setErrors((prev) => ({
            ...prev,
            [field]: '',
          }));
          return true;
        } else {
          return (
            !requiredInsurnaceFields[field] ||
            !!values.patient.insurance[
              field as keyof typeof values.patient.insurance
            ]
          );
        }
      });
      // Determine if the step is valid
      const isValidStep = isMedicare
        ? allRequiredFieldsValid && allMedicareFieldsValid && !hasErrors
        : allRequiredFieldsValid &&
          allRequiredInsuranceFieldsValid &&
          !hasErrors;
      setErrors(newErrors);
      setValidStep(isValidStep);
    }, [
      errors,
      requiredInsurnaceFields,
      isMedicare,
      setValidStep,
      isMedicareOnly,
      values,
      setIsMedicare,
      isIncomeFocused,
      medicareFPLEligibility,
      isMedicarePartD,
      medicareFPLEligibilityCheck,
      isMedicarePartC,
    ]);
    const handleChange = useCallback(
      (e: { target: { name: string; value: unknown } }, object: clientInfo) => {
        const { name, value } = e.target;

        // Update the correct field in the object
        const updatedObject = { ...object, [name]: value };

        setValues((prevValues) => ({
          ...prevValues,
          patient: {
            ...prevValues.patient,
            ...updatedObject,
          },
        }));

        requiredCheck();
      },
      [requiredCheck, setValues]
    );
    const handleIncomeChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.replace(/\D/g, '');

        if (value === '') {
          setIncomeValue('');
          setValues((prevValues) => ({
            ...prevValues,
            patient: {
              ...prevValues.patient,
              income: 0, // Ensure the income is set to 0 in formValues
            },
          }));
        } else {
          const formattedValue = '$' + parseInt(value).toLocaleString();
          setIncomeValue(formattedValue);
          setValues((prevValues) => ({
            ...prevValues,
            patient: {
              ...prevValues.patient,
              income: parseInt(value), // Ensure the income is updated in formValues
            },
          }));
        }
        if (!isIncomeFocused) {
          requiredCheck();
        }
      },
      [isIncomeFocused, requiredCheck, setValues]
    );

    const MedicareFieldsComponent = useMemo(() => {
      return isMedicare || isMedicareOnly ? (
        <MedicareFields
          values={values}
          setValues={setValues}
          errors={errors}
          setValidStep={setValidStep}
          isMedicarePartD={isMedicarePartD}
          setIsMedicarePartD={setIsMedicarePartD}
          isMedicarePartC={isMedicarePartC}
          setIsMedicarePartC={setIsMedicarePartC}
          isSpouseOnSSC={isSpouseOnSSC}
        />
      ) : null;
    }, [
      isMedicare,
      isMedicareOnly,
      values,
      setValues,
      errors,
      setValidStep,
      isMedicarePartD,
      isMedicarePartC,
      isSpouseOnSSC,
    ]);

    const InsuranceFieldsComponent = useMemo(() => {
      return !isMedicare && !isMedicareOnly ? (
        <InsuranceFields
          values={values}
          setValues={setValues}
          insuranceDataList={insuranceDataList}
          setRequiredInsurnaceFields={setRequiredInsurnaceFields}
        />
      ) : null;
    }, [isMedicare, isMedicareOnly, values, setValues, insuranceDataList]);

    const datePickerComponent = useMemo(() => {
      return (
        <DatePickerValue
          isMedicareOnly={isMedicareOnly}
          setOpenAgeDialog={setOpenAgeDialog}
          onChange={handleChangeBirthday}
          setIsMedicare={setIsMedicare}
          calculateAgeEligibility={calculateAgeEligibility}
          disability={values.patient.disabled}
          errors={errors}
          setErrors={setErrors}
          values={values}
        />
      );
    }, [
      isMedicareOnly,
      handleChangeBirthday,
      setIsMedicare,
      calculateAgeEligibility,
      values,
      errors,
    ]);

    useEffect(() => {
      setIncomeValue(
        values.patient.income.toString() !== ''
          ? '$' + parseInt(values.patient.income.toString()).toLocaleString()
          : ''
      );
    }, [values.patient.income]);

    useEffect(() => {
      // Call the check only if the values or errors have actually changed
      const shouldRunCheck =
        JSON.stringify(values) !== JSON.stringify(prevValues) ||
        JSON.stringify(errors) !== JSON.stringify(prevErrors);

      if (shouldRunCheck) {
        requiredCheck();
        setPrevValues(values);
        setPrevErrors(errors);
      }
    }, [values, errors, requiredCheck, prevValues, prevErrors]);

    useEffect(() => {
      setValues((prev) => ({
        ...prev,
        patient: {
          ...prev.patient,
          insurance: {
            company: isMedicare ? 'medicare' : '',
            product: '',
            plan: '',
            policy: '',
          },
        },
      }));
    }, [isMedicare, medicareFPLEligibility, setValues]);

    useEffect(() => {
      if (isMedicarePartD) {
        setValues((prev) => ({
          ...prev,
          patient: {
            ...prev.patient,
            medicare: {
              ...prev.patient.medicare,
              advantageCoverage: 'no',
              advantage: 'no',
            },
          },
        }));
      }
    }, [isMedicarePartD, setValues]);

    useEffect(() => {
      if (loadingAgentList || values.patient.source !== 'abi') return;
      let orderdList = agentList?.docs.map((doc) => doc.data());
      orderdList = orderdList?.sort((a, b) => {
        if (a.agentName > b.agentName) return 1;
        if (a.agentName < b.agentName) return -1;
        return 0;
      });
      setAgentDataList(orderdList!);
    }, [loadingAgentList, agentList, values.patient.source]);

    const agentOptionsList = useMemo(() => {
      if (agentDataList) {
        return agentDataList.map((option) => option.agentName);
      }
      return [];
    }, [agentDataList]);

    return (
      <>
        <Typography variant="h1" textAlign={'center'}>
          Welcome to TPRX pharmacy program onboarding
        </Typography>
        <Stack>
          <Typography
            variant="h2"
            textAlign={'center'}
            color={theme.palette.primary.main}>
            Let's quickly determine the best program for you
          </Typography>
          <Typography variant="h3" textAlign={'center'}>
            Your answers here will guide us to tailor everything perfectly to
            your needs
          </Typography>
        </Stack>
        <Stack className="tprx-card">
          <Grid container spacing={2}>
            {formConfig[0].fields.map((field) => (
              <Grid item xs={12} sm={4} key={field.name}>
                {(() => {
                  switch (field.type) {
                    case 'income':
                      return (
                        <TextField
                          onFocus={() => setIsIncomeFocused(true)}
                          required
                          error={errors['income'] ? true : false}
                          helperText={errors['income']}
                          id="income"
                          name="income"
                          label={
                            isMedicare
                              ? 'Household Annual Social security Income'
                              : 'Yearly Income'
                          }
                          onChange={handleIncomeChange}
                          value={
                            values.patient.income.toString() !== ''
                              ? incomeValue
                              : ''
                          }
                          onBlur={() => {
                            setIsIncomeFocused(false);
                            medicareFPLEligibilityCheck();
                          }}
                          inputProps={{
                            inputMode: 'numeric',
                          }}
                          margin="normal"
                          fullWidth
                        />
                      );
                    case 'date':
                    case 'radio':
                      return (
                        <>
                          <FormControl required={field.required}>
                            <FormLabel id="disabled">{field.label}</FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby="disabled"
                              value={values.patient.disabled}
                              name="disabled"
                              sx={{ mt: 2 }}
                              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                handleChange(e, values.patient);
                                if (e.target.value === 'yes') {
                                  setIsMedicare(true);
                                } else {
                                  setIsMedicare(
                                    calculateAgeEligibility(
                                      values.patient.dob,
                                      'min',
                                      65
                                    )
                                  );
                                }
                              }}>
                              <FormControlLabel
                                value="no"
                                control={<Radio />}
                                label="No"
                                sx={{
                                  pr: 2,
                                  border: '1px solid #d9d9d9',
                                  borderRadius: '4px',
                                  backgroundColor: '#fff',
                                  ml: 0,
                                }}
                              />
                              <FormControlLabel
                                value="yes"
                                control={<Radio />}
                                label="Yes"
                                sx={{
                                  pr: 2,
                                  border: '1px solid #d9d9d9',
                                  borderRadius: '4px',
                                  backgroundColor: '#fff',
                                  ml: 0,
                                }}
                              />
                            </RadioGroup>
                          </FormControl>
                        </>
                      );
                    case 'text':
                      if (field.name.includes('agent')) {
                        if (values.patient.source === 'abi' && agentDataList) {
                          if (field.name === 'agentName')
                            return (
                              <Autocomplete
                                disablePortal
                                options={agentOptionsList}
                                value={values.patient.agentInfo.agentName}
                                onChange={(_e, value) => {
                                  handleAgentChange(value, true, field.name);
                                }}
                                renderOption={(props: any, option: any) => {
                                  const { key, ...optionProps } = props;
                                  return (
                                    <Stack
                                      key={key}
                                      component="li"
                                      {...optionProps}
                                      direction={'row'}>
                                      <Stack direction={'column'} mr={'auto'}>
                                        <Typography variant="body1">
                                          {option}
                                        </Typography>
                                      </Stack>
                                    </Stack>
                                  );
                                }}
                                renderInput={(params: any) => (
                                  <TextField
                                    {...params}
                                    fullWidth
                                    name={'agentName'}
                                    value={
                                      values.patient.agentInfo.agentName || null
                                    }
                                    label={'Select Agent'}
                                    variant="outlined"
                                    sx={{ mt: 2 }}
                                  />
                                )}
                              />
                            );
                          else if (field.name === 'agentId') {
                            return (
                              <TextField
                                required={field.required}
                                label={field.label}
                                name={field.name}
                                type={field.type}
                                value={values.patient.agentInfo.agentId || ''}
                                onChange={(
                                  e: ChangeEvent<HTMLInputElement>
                                ) => {
                                  const value = e.target.value;
                                  handleAgentChange(value, false, field.name);
                                }}
                                error={errors[field.name] ? true : false}
                                helperText={errors[field.name]}
                                margin="normal"
                                fullWidth
                              />
                            );
                          }
                        } else if (values.patient.source === 'zch') {
                          if (field.name === 'agentAgency') {
                            return (
                              <TextField
                                required={field.required}
                                label={field.label}
                                name={field.name}
                                type={field.type}
                                value={
                                  values.patient.agentInfo[field.name] || ''
                                }
                                onChange={(
                                  e: ChangeEvent<HTMLInputElement>
                                ) => {
                                  const value = e.target.value;
                                  handleAgentChange(value, false, field.name);
                                }}
                                error={errors[field.name] ? true : false}
                                helperText={errors[field.name]}
                                margin="normal"
                                fullWidth
                              />
                            );
                          }
                        } else {
                          if (field.name === 'agentAgency') return;
                          return (
                            <TextField
                              required={field.required}
                              label={field.label}
                              name={field.name}
                              type={field.type}
                              value={
                                (
                                  values.patient.agentInfo as {
                                    [key: string]: any;
                                  }
                                )[field.name] || ''
                              }
                              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                if (field.name === 'agentPhone') {
                                  formatPhoneNumber(e);
                                }
                                const value = e.target.value;
                                handleAgentChange(value, false, field.name);
                              }}
                              error={errors[field.name] ? true : false}
                              helperText={errors[field.name]}
                              margin="normal"
                              fullWidth
                            />
                          );
                        }
                      }
                      if (field.name === 'dob') {
                        return datePickerComponent;
                      } else {
                        return (
                          <TextField
                            required={field.required}
                            label={field.label}
                            name={field.name}
                            type={field.type}
                            value={
                              values.patient[field.name as keyof clientInfo] ||
                              ''
                            }
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                              if (field.name === 'numinhouse') {
                                if (parseInt(e.target.value) > 99) {
                                  return;
                                }
                                if (parseInt(e.target.value) >= 1) {
                                  setIsSpouseOnSSC(true);
                                } else {
                                  setIsSpouseOnSSC(false);
                                }
                              }

                              handleChange(e, values.patient);
                            }}
                            error={errors[field.name] ? true : false}
                            helperText={errors[field.name]}
                            margin="normal"
                            fullWidth
                          />
                        );
                      }
                    default:
                      return (
                        <TextField
                          required={field.required}
                          label={field.label}
                          name={field.name}
                          type={field.type}
                          value={
                            field.name === 'agentEmail'
                              ? values.patient.agentInfo.agentEmail || ''
                              : values.patient[
                                  field.name as keyof clientInfo
                                ] || ''
                          }
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            if (field.name === 'agentEmail') {
                              e.target.value = e.target.value.toLowerCase();
                              handleAgentChange(
                                e.target.value,
                                false,
                                field.name
                              );
                              return;
                            }
                            if (field.name === 'numinhouse') {
                              if (parseInt(e.target.value) > 99) {
                                return;
                              }
                              if (parseInt(e.target.value) > 1) {
                                setIsSpouseOnSSC(true);
                              } else {
                                setIsSpouseOnSSC(false);
                              }
                            }
                            handleChange(e, values.patient);
                          }}
                          error={errors[field.name] ? true : false}
                          helperText={errors[field.name]}
                          margin="normal"
                          fullWidth
                        />
                      );
                  }
                })()}
              </Grid>
            ))}
            <Divider sx={{ width: '100%', mt: 4, mb: 4 }} />
            {MedicareFieldsComponent}
            {InsuranceFieldsComponent}
          </Grid>
        </Stack>
        <QualificationDialog
          open={openAgeDialog}
          customTitle={customDialogTitle}
          customSubtitle={customDialogSubtitle}
          handleClose={handleClose}
          qulificationGroup={dialogText.current}
          isEligible={false}
        />
      </>
    );
  }
);

export default FirstStep;
