/* eslint-disable @typescript-eslint/no-explicit-any */
import { docInfo, orderInfo } from './types';
import emailjs from '@emailjs/browser';

const values = JSON.parse(sessionStorage.getItem('formData') || '{}');
const userData = JSON.parse(sessionStorage.getItem('userData') || '{}');

// Helper function for Source field

// Helper function for Patient field
function getPatientField(patient: any): string {
  return `
*Patient:*
First Name: ${patient.fname}
Middle Name: ${patient.mname}
Last Name: ${patient.lname}
Apt: ${patient.apt}
Address: ${patient.fullAddress}
SSN: ${patient.ssn}
Birthday: ${patient.dob}
Email: ${patient.email}
Income: ${patient.income}
Number in Household: ${patient.numinhouse}
Emergency Contact Name: ${patient.emerContactName}
Emergency Contact Phone: ${patient.emerContactPhone}
Marital Status: ${patient.marital}
Employed: ${patient.employed}
On Social Security: ${patient.onSocialSecurityChecks ? 'Yes' : 'No'}
Disabled: ${patient.disabled === 'yes' ? 'Yes' : 'No'}
Phone: ${patient.phone}
Gender: ${patient.sex}`;
}

function getAgentField(agent: any): string {
  return `
*Agent Details:*
Agent Name: ${agent.agentName || '-'}
Agent ID: ${agent.agentId || '-'}
Agent Phone: ${agent.agentPhone || '-'}
Agent Email: ${agent.agentEmail || '-'}
Agent Agency (Zach groups): ${agent.agentAgency || '-'}`;
}

// Helper function for Insurance field
function getInsuranceField(insurance: any): string {
  return `
*Insurance:*
Company: ${insurance.company || '-'}
Product: ${insurance.product || '-'}
Plan: ${insurance.plan || '-'}
Policy: ${insurance.policy || '-'}`;
}

// Helper function for Medicare field
function getMedicareField(medicare: any): string {
  return `
*Medicare:*
Part D: ${medicare.partD || '-'}
Advantage: ${medicare.advantage || '-'}
Advantage Coverage: ${medicare.advantageCoverage || '-'}
Both on SSC: ${medicare.bothSSC || '-'}
Supplement: ${medicare.supplement || '-'}
MBI: ${medicare.mbi || '-'}`;
}

// Helper function for Doctor section
function getDoctorFields(doctors: any[]): any[] {
  if (!doctors.length) return [];
  return doctors.map((doctor) => ({
    type: 'mrkdwn',
    text: `
*Doctor:*
First Name: ${doctor.fname}
Middle Name: ${doctor.mname}
Last Name: ${doctor.lname}
Phone: ${doctor.phone}
Fax: ${doctor.fax}
Address: ${doctor.fullAddress}, ${doctor.zip}, Suite: ${doctor.suite}
Facility: ${doctor.facility}`,
  }));
}

// Helper function for Order section
function getOrderFields(orders: any[]): any[] {
  if (!orders.length) return [];
  return orders.map((order) => ({
    type: 'mrkdwn',
    text: `
*Order:*
Doctor Name: ${order.doctorName}
Medication Name: ${order.medicationName}
Medication Strength: ${order.medicationStrength}`,
  }));
}

export async function sendLogToSlack(values: any) {
  const baseBlocks = [
    {
      type: 'header',
      text: {
        type: 'plain_text',
        text: 'New Application Submission Before Payment',
        emoji: true,
      },
    },
    {
      type: 'section',
      fields: [
        {
          type: 'mrkdwn',
          text: values.patient.source,
        },
      ],
    },
    {
      type: 'divider',
    },
    {
      type: 'section',
      fields: [
        {
          type: 'mrkdwn',
          text: getPatientField(values.patient),
        },
        {
          type: 'mrkdwn',
          text: getAgentField(values.patient.agentInfo),
        },
        {
          type: 'mrkdwn',
          text: getInsuranceField(values.patient.insurance),
        },
        {
          type: 'mrkdwn',
          text: getMedicareField(values.patient.medicare),
        },
      ],
    },
  ];

  const additionalBlocks = [
    {
      type: 'divider',
    },
    {
      type: 'section',
      fields: getDoctorFields(values.doctors),
    },
    {
      type: 'divider',
    },
    {
      type: 'section',
      fields: getOrderFields(values.orders),
    },
  ];

  const message = {
    blocks: [...baseBlocks, ...additionalBlocks],
  };
  console.log(message);
  try {
    const response = await fetch(
      'https://us-central1-transparent-rx.cloudfunctions.net/slackSubmission',
      // 'http://127.0.0.1:5001/transparent-rx/us-central1/slackSubmission',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(message),
      }
    );
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const res = await response.json();
    return res as { ok: boolean };
  } catch (err) {
    console.log(err);
  }
}
export async function sendErrorToSlack(errorDetails: any) {
  if (errorDetails.environment === 'development') return;
  const message = {
    blocks: [
      {
        type: 'header',
        text: {
          type: 'plain_text',
          text: '🚨 *Error Occurred* 🚨',
          emoji: true,
        },
      },
      {
        type: 'section',
        fields: [
          {
            type: 'mrkdwn',
            text: `*Message:*\n${errorDetails.message}`,
          },
        ],
      },
      {
        type: 'section',
        fields: [
          {
            type: 'mrkdwn',
            text: `*Stack:*\n${errorDetails.stack}`,
          },
          {
            type: 'mrkdwn',
            text: `*Environment:*\n${errorDetails.environment}`,
          },
          {
            type: 'mrkdwn',
            text: `*User Agent:*\n${errorDetails.userAgent}`,
          },
          {
            type: 'mrkdwn',
            text: `*Timestamp:*\n${new Date(
              errorDetails.timestamp
            ).toISOString()}`,
          },
          {
            type: 'mrkdwn',
            text: `*URL:*\n${errorDetails.url}`,
          },
        ],
      },
      {
        type: 'divider',
      },
    ],
  };

  if (values.patient) {
    message.blocks.push(
      {
        type: 'section',
        fields: [
          {
            type: 'mrkdwn',
            text: `*Source:*\n${values.patient.source}`,
          },
          {
            type: 'mrkdwn',
            text: `*Agent Details:*\n${values.patient.agentInfo.agentName} + ${values.patient.agentInfo.agentId}`,
          },
        ],
      },
      {
        type: 'divider',
      },
      {
        type: 'section',
        fields: [
          {
            type: 'mrkdwn',
            text: `*Patient:*\nFirst Name: ${values.patient.fname}\nMiddle Name: ${values.patient.mname}\nLast Name: ${values.patient.lname}\nApt: ${values.patient.apt}\nAddress: ${values.patient.fullAddress}\nSSN: ${values.patient.ssn}\nBirthday: ${values.patient.dob}\nEmail: ${values.patient.email}\nIncome: ${values.patient.income}`,
          },
          {
            type: 'mrkdwn',
            text: `*Insurance:*\nCompany: ${values.patient.insurance.company}\nProduct: ${values.patient.insurance.product}\nPlan: ${values.patient.insurance.plan}\nPolicy: ${values.patient.insurance.policy}`,
          },
          {
            type: 'mrkdwn',
            text: `*Medicare:*\nPart D: ${values.patient.medicare.partD}\nAdvantage: ${values.patient.medicare.advantage}\nSupplement: ${values.patient.medicare.supplement}\nAdvantage Coverage: ${values.patient.medicare.advantageCoverage}\nInsurance Name: ${values.patient.medicare.insuranceName}\nInsurance Address: ${values.patient.medicare.insuranceAddress}\nPlan Name: ${values.patient.medicare.planName}\nPlan Phone: ${values.patient.medicare.planPhone}\nGroup Number: ${values.patient.medicare.groupNumber}\nRxBIN: ${values.patient.medicare.rxbin}\nRxPCN: ${values.patient.medicare.rxpcn}\nRxGroup: ${values.patient.medicare.rxgroup}\nRxMember ID: ${values.patient.medicare.rxmemberid}\nPart Aid: ${values.patient.medicare.partAid}\nMBI: ${values.patient.medicare.mbi}`,
          },
        ],
      },
      {
        type: 'divider',
      },

      ...values.doctors.map((doctor: docInfo) => ({
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: `*Doctor:*\nFirst Name: ${doctor.fname}\nMiddle Name: ${doctor.mname}\nLast Name: ${doctor.lname}\nPhone: ${doctor.phone}\nFax: ${doctor.fax}\nAddress: ${doctor.fullAddress}, ${doctor.zip}, suite:${doctor.suite}\nFacility: ${doctor.facility}`,
        },
      })),

      {
        type: 'divider',
      },
      ...values.orders.map((order: orderInfo) => ({
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: `*Order:*\nDoctor Name: ${order.doctorName}\nMedication Name: ${order.medicationName}\nMedication Strength: ${order.medicationStrength}`,
        },
      })),

      {
        type: 'divider',
      },
      {
        type: 'section',
        fields: [
          {
            type: 'mrkdwn',
            text: `*Agreements:*\nIncome Statement: ${values.agreements.incomeStatement.toString()}\nPolicies Statement: ${values.agreements.policiesStatement.toString()}\nInformation Statement: ${values.agreements.informationStatement.toString()}\nEffective Statement: ${values.agreements.effectiveStatement.toString()}`,
          },
        ],
      }
    );
  } else if (userData) {
    message.blocks.push({
      type: 'section',
      fields: [
        {
          type: 'mrkdwn',
          text: `*user email: ${userData.email}`,
        },
        {
          type: 'mrkdwn',
          text: `*user uniqueId: ${userData.uniqueId}`,
        },
      ],
    });
  }
  try {
    const response = await fetch(
      'https://slackerrors-qqjaqi7y5q-uc.a.run.app',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(message),
      }
    );
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
  } catch (err) {
    console.log(err);
  }
}

export async function sendLogToEmail(values: any) {
  const doctorsText = getDoctorFields(values.doctors)
    .map((doctor) => doctor.text)
    .join('\n');
  const ordersText = getOrderFields(values.orders)
    .map((order) => order.text)
    .join('\n');
  doctorsText.replace('*Doctor*', '');
  ordersText.replace('*Order*', '');
  const patientData = getPatientField(values.patient);
  patientData.replace('*Patient*', '');
  const insuranceData = getInsuranceField(values.patient.insurance);
  insuranceData.replace('*Insurance:*', '');
  const medicareData = getMedicareField(values.patient.medicare);
  medicareData.replace('*Medicare:*', '');
  const emailData = {
    source: values.patient.source,
    agentData: values.patient.agentInfo.agentName
      ? `${values.patient.agentInfo.agentName}  ${values.patient.agentInfo.agentId}`
      : 'none',
    patientData: patientData,
    insuranceData: insuranceData,
    medicareData: medicareData,
    doctorsData: doctorsText,
    ordersData: ordersText,
  };

  emailjs
    .send('default_service', 'template_h2dxlts', emailData!, {
      publicKey: 'RTSZKvi6HCOYvdkil',
    })
    .then(
      () => {},
      (error) => {
        console.log('FAILED...', error.text);
      }
    );
}
