import {
  Button,
  Dialog,
  DialogContent,
  Stack,
  Typography,
} from '@mui/material';

function QualificationDialog({
  open,
  handleClose,
  qulificationGroup,
  isEligible,
  medName,
  medExist,
  isMedicare,
  customTitle,
  customSubtitle,
}: {
  open: boolean;
  handleClose: () => void;
  qulificationGroup: string;
  isEligible: boolean;
  medName?: string;
  medExist?: boolean;
  isMedicare?: boolean;
  customTitle?: string;
  customSubtitle?: string;
}) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={'md'}
      PaperProps={{
        sx: {
          borderRadius: 4,
          // backgroundColor: '#F1E1E1',
        },
      }}>
      <DialogContent>
        <Stack spacing={3} p={2} alignItems={'center'}>
          {medExist ? (
            <Typography
              variant="h3"
              fontWeight={600}
              textAlign={'center'}
              color={'error'}>
              You can only order each medication once
            </Typography>
          ) : isEligible ? (
            <>
              <Typography
                variant="h3"
                fontWeight={600}
                textAlign={'center'}
                color={'success'}>
                Sorry, you are not eligible to apply for {medName}
              </Typography>

              <Typography variant="body1" fontWeight={600} textAlign={'center'}>
                {isMedicare
                  ? `Your pharmacy expenses doesn't meet the criteria for ${medName}`
                  : `Please check that your income is correct, and if you take care of your parents or children, count them in the number on household, this numbers affect the eligibility criteria`}
              </Typography>
            </>
          ) : (
            <>
              <Typography
                variant="h3"
                fontWeight={600}
                textAlign={'center'}
                color={'error'}>
                {customTitle !== '' && customTitle !== undefined
                  ? customTitle
                  : `We are currently enhancing our services to better accommodate individuals ${qulificationGroup}.`}
              </Typography>
              <Typography variant="body1" fontWeight={600} textAlign={'center'}>
                {customSubtitle !== '' && customSubtitle !== undefined
                  ? customSubtitle
                  : `We expect these improvements to be available soon.
                Thank you for your patience and understanding. Please check back
                soon!`}
              </Typography>
            </>
          )}
          <Button onClick={handleClose} variant="contained">
            I Understand
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export default QualificationDialog;
