import React, { createContext } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import './index.css';
import { ThemeProvider } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import theme from './theme';
import { DocumentData } from 'firebase/firestore';
import { User } from 'firebase/auth';
import * as Sentry from '@sentry/react';
import logger from './utils/slackLogger';
import { BrowserRouter } from 'react-router-dom';

if (!(window.location.hostname === 'localhost')) {
  Sentry.init({
    dsn: 'https://5b04a559e778d2ca67f38d74da290ef1@o4505941303951360.ingest.us.sentry.io/4506881515782144',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['*'],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}
type isLoggedType = {
  user: User | null;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
};

type userDataType = {
  userData: DocumentData | null;
  setUserData: React.Dispatch<React.SetStateAction<DocumentData | null>>;
};

type healthCareDataType = {
  healthcareData: DocumentData[] | null;
  setHealthcareData: React.Dispatch<
    React.SetStateAction<DocumentData[] | null>
  >;
};

type ordersDataType = {
  ordersData: DocumentData[] | null;
  setOrdersData: React.Dispatch<React.SetStateAction<DocumentData[] | null>>;
};

type isValidFactorType = {
  isValidFactor: boolean | null;
  setIsValidFactor: React.Dispatch<React.SetStateAction<boolean>>;
};
type userPhoneNumberType = {
  userPhoneNumber: string | null;
  setUserPhoneNumber: React.Dispatch<React.SetStateAction<string | null>>;
};
const ordersDataContextState = {
  ordersData: null,
  setOrdersData: () => {},
};

const healthCareDataContextState = {
  healthcareData: null,
  setHealthcareData: () => {},
};

const userDataContextState = {
  userData: null,
  setUserData: () => {},
};
const iLoginContextState = {
  user: null,
  setUser: () => {},
};
const isValidFactorContextState = {
  isValidFactor: null,
  setIsValidFactor: () => {},
};

const userPhoneNumberContextState = {
  userPhoneNumber: null,
  setUserPhoneNumber: () => {},
};

export const userState = createContext<isLoggedType>(iLoginContextState);
export const userDataState = createContext<userDataType>(userDataContextState);
export const healthcareDataState = createContext<healthCareDataType>(
  healthCareDataContextState
);
export const ordersDataState = createContext<ordersDataType>(
  ordersDataContextState
);
export const isValidFactorState = createContext<isValidFactorType>(
  isValidFactorContextState
);
export const userPhoneNumberState = createContext<userPhoneNumberType>(
  userPhoneNumberContextState
);
// Global error handler
window.onerror = function (message, source, lineno, colno, error) {
  logger.logError(error, { message, source, lineno, colno });
};
// Global unhandled rejection handler
window.onunhandledrejection = function (event) {
  logger.logError(event.reason, { event });
};
ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>
);
