/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Grid,
  TextField,
  Autocomplete,
  MenuItem,
  Typography,
  Stack,
  Button,
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import {
  ChangeEvent,
  useCallback,
  useMemo,
  useRef,
  useState,
  useEffect,
} from 'react';
import { collection, DocumentData } from 'firebase/firestore';
import React from 'react';
import { formValues } from '../../../utils/types';
import QualificationDialog from '../QualificationDialog';
import { fplCalculator } from '../../../utils/utilFuntions';
import theme from '../../../theme';
import { useCollection } from 'react-firebase-hooks/firestore';
import { db } from '../../../firebase-setup/firebase';

function SecondStep({
  medicationsList,
  values,
  setValues,
  setValidStep,
  numMeds,
  setNumMeds,
}: {
  medicationsList: DocumentData[] | null;
  values: formValues;
  setValues: React.Dispatch<React.SetStateAction<formValues>>;
  setValidStep: React.Dispatch<React.SetStateAction<boolean>>;
  numMeds: number;
  setNumMeds: React.Dispatch<React.SetStateAction<number>>;
}) {
  const [medicationStrengths, setMedicationStrengths] = useState<
    Array<{ strength: string; price: string }[] | null>
  >(Array(numMeds).fill(null));
  const [currentMedName, setCurrnetMedName] = useState<string>('');

  const loadedParams = useRef<boolean>(false);
  const [medCosts, setMedCosts] = useState<Array<string> | null>(
    Array(numMeds).fill('')
  );
  const [medicationDetailsText, setMedicationDetailsText] = useState<
    Array<string>
  >(Array(numMeds).fill(''));
  const [openEligibilityDialog, setOpenEligibilityDialog] =
    useState<boolean>(false);
  const [isFirstTextFieldChanged, setIsFirstTextFieldChanged] = useState([
    false,
    false,
    false,
    false,
  ]);
  const [medicareExtraDetails, loadingMedicareExtraDetails] = useCollection(
    collection(db, 'medicareExtraDetails'),
    {
      snapshotListenOptions: { includeMetadataChanges: false },
    }
  );
  const [medicareExtraDetailsList, setMedicareExtraDetailsList] = useState<
    DocumentData[] | null
  >(null);

  const filterMedicationsOptions = (options: any[], state: any) => {
    const inputValue = state.inputValue;
    return options.filter((option) => {
      if (
        option.name.toLowerCase().includes(inputValue.toLowerCase()) ||
        option.genericName.toLowerCase().includes(inputValue.toLowerCase())
      ) {
        return option;
      }
      return false;
    });
  };
  const clientFPL = fplCalculator(
    parseInt(values.patient.numinhouse),
    parseInt(values.patient.income as string)
  );

  const getMedCost = useCallback(
    (medication: string, strength: string) => {
      const med = medicationsList?.find((item) => item.name === medication);
      if (!med) return null;

      const medOption = med.options.find(
        (item: { strength: string; price: string }) =>
          item.strength === strength
      );
      if (!medOption) return null;
      return medOption.price;
    },
    [medicationsList]
  );

  const handleClose = () => {
    setOpenEligibilityDialog(false);
  };
  const [medExist, setMedExist] = useState<boolean>(false);

  const requiredCheck = useCallback(() => {
    // Start with assumption that form is valid
    let isValid = true;

    if (values.patient.insurance.company === 'medicare') {
      // Get all the questions that are required based on selected medications
      const requiredQuestions: string[] = [];

      // Add required questions based on selected medications
      values.orders.forEach((order) => {
        if (order.medicationName) {
          const medName = order.medicationName.split(' (')[0];

          if (medicareExtraDetailsList) {
            medicareExtraDetailsList.forEach((detail) => {
              if (detail.medications.includes(medName)) {
                if (
                  detail.id === 'donutHole' &&
                  values.patient.medicare.partD === 'no' &&
                  values.patient.medicare.advantage === 'no'
                ) {
                  return;
                }

                if (
                  detail.id === 'spent7.5' &&
                  values.patient.medicare.partD === 'no'
                ) {
                  return;
                }

                requiredQuestions.push(detail.id);
              }
            });
          }
        }
      });

      requiredQuestions.forEach((questionId) => {
        const questionDetail = medicareExtraDetailsList?.find(
          (detail) => detail.id === questionId
        );

        if (questionDetail) {
          const userAnswer = values.patient.medicareExtraDetails[questionId]
            ? 'yes'
            : 'no';
          const isAnswerValid = userAnswer === questionDetail.requiredAnswer;

          if (!isAnswerValid) {
            isValid = false;
          }
        }
      });
    }

    for (let i = 0; i < numMeds; i++) {
      // First medication is required
      if (values.orders[i].medicationName === '' && i === 0) {
        isValid = false;
      }
      // If medication name is provided but strength is missing, form is invalid
      else if (
        values.orders[i].medicationName !== '' &&
        values.orders[i].medicationStrength === ''
      ) {
        isValid = false;
      }
    }

    setValidStep(isValid);
  }, [
    numMeds,
    values.patient.insurance.company,
    values.patient.medicareExtraDetails,
    values.patient.medicare.partD,
    values.patient.medicare.advantage,
    values.orders,
    medicareExtraDetailsList,
    setValidStep,
  ]);

  useEffect(() => {
    requiredCheck();
  }, [values.orders, values.patient.medicareExtraDetails, requiredCheck]);

  const handleMedicationChange = useCallback(
    async (value: string | null, index: number, isFromQuery: boolean) => {
      setMedExist(false);
      medCosts![index] = 'Select Medication & Strength';
      const selectedMed = medicationsList!.find(
        (item) =>
          item.name ===
          (value as string)?.slice(0, (value as string).indexOf(' ('))
      );
      if (!selectedMed) return;
      if (!isFromQuery) {
        // Check if the medication is already chosen
        const isMedAlreadyChosen = values.orders.some(
          (order) => order.medicationName === value
        );
        if (isMedAlreadyChosen) {
          setMedExist(true);
          setOpenEligibilityDialog(true);
          return;
        }
      }
      setValues((prev) => ({
        ...prev,
        orders: prev.orders.map((order, i) =>
          i === index
            ? {
                ...order,
                medicationName: value as string,
                medicationDelivery: selectedMed.medicationDelivery,
              }
            : order
        ),
      }));
      setCurrnetMedName(selectedMed.name);

      if (selectedMed.brand) {
        let isntEligible = false;
        if (selectedMed.name === 'Xolair') {
          Number(values.patient.income) < 150000
            ? (isntEligible = false)
            : (isntEligible = true);
        } else {
          isntEligible = clientFPL > selectedMed.fpl;
        }

        if (isntEligible && values.patient.insurance.company !== 'medicare') {
          setOpenEligibilityDialog(isntEligible);
          setValues((prev) => ({
            ...prev,
            orders: prev.orders.map((order, i) =>
              i === index
                ? {
                    ...order,
                    medicationName: '',
                  }
                : order
            ),
          }));

          setMedicationStrengths((prev) => {
            const newStrengths = [...prev];
            newStrengths[index] = null;
            return newStrengths;
          });
          return;
        }
      }

      setMedicationStrengths((prev) => {
        const newStrengths = [...prev];
        newStrengths[index] = selectedMed.options || null;
        return newStrengths;
      });
      setMedicationDetailsText((prev) => {
        const newDetails = [...prev];
        if (selectedMed.brand) {
          newDetails[index] =
            'Once your healthcare provider complete our simple steps, expect to get your medication directly from the manufacturer in a 90 days supply in about 3 week';
        } else {
          newDetails[index] =
            'Once your healthcare provider complete our simple steps, expect to get your medication directly from the manufacturer in a 90 days supply in about 1 week';
        }
        return newDetails;
      });
      setIsFirstTextFieldChanged((prev) => {
        const newIsFirstTextFieldChanged = [...prev];
        newIsFirstTextFieldChanged[index] = true;
        return newIsFirstTextFieldChanged;
      });
    },

    [
      clientFPL,
      medCosts,
      medicationsList,
      setValues,
      values.orders,
      values.patient.income,
      values.patient.insurance.company,
    ]
  );
  const handleStrengthChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, index: number) => {
      const selectedStrength = e.target.value;
      setValues((prev) => ({
        ...prev,
        orders: prev.orders.map((order, i) =>
          i === index
            ? {
                ...order,
                medicationStrength: selectedStrength,
              }
            : order
        ),
      }));
      setMedCosts((prev) => {
        const newCosts = [...(prev ?? [])];
        newCosts[index] = getMedCost(
          values.orders[index].medicationName.slice(
            0,
            values.orders[index].medicationName.indexOf(' (')
          ),
          selectedStrength
        );
        return newCosts;
      });
    },
    [setValues, getMedCost, values.orders]
  );

  const handleMedicareExtraChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const name = e.target.name;
      const value = e.target.value === 'yes' ? true : false;

      setValues((prev) => ({
        ...prev,
        patient: {
          ...prev.patient,
          medicareExtraDetails: {
            ...prev.patient.medicareExtraDetails,
            [name]: value,
          },
        },
      }));
    },
    [setValues]
  );
  const extraMedicareFields = useMemo(
    () => (index: number) => {
      if (!medicareExtraDetailsList || !values.orders[index].medicationName)
        return null;

      const extraFieldDetails = medicareExtraDetailsList.filter((med) =>
        med.medications.includes(
          values.orders[index].medicationName.split(' (')[0]
        )
      );
      if (extraFieldDetails.length === 0) return null;
      if (
        extraFieldDetails[0].id === 'donutHole' &&
        values.patient.medicare.partD === 'no' &&
        values.patient.medicare.advantage === 'no'
      )
        return null;

      if (
        extraFieldDetails[0].id === 'spent7.5' &&
        values.patient.medicare.partD === 'no'
      )
        return null;

      // Check if this answer is valid
      const userAnswer = values.patient.medicareExtraDetails[
        `${extraFieldDetails[0].id}`
      ]
        ? 'yes'
        : 'no';
      const isAnswerValid = userAnswer === extraFieldDetails[0].requiredAnswer;

      return (
        <Grid item xs={12}>
          <Stack spacing={2}>
            {/* Remove required prop to prevent automatic asterisk */}
            <FormControl>
              <FormLabel id={extraFieldDetails[0].id}>
                {extraFieldDetails[0].question}
                {!isAnswerValid && (
                  <Typography
                    color="error"
                    variant="caption"
                    display="block"
                    sx={{ mt: 1 }}>
                    * Please select the correct answer to proceed with your
                    order.
                  </Typography>
                )}
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby={extraFieldDetails[0].id}
                value={
                  values.patient.medicareExtraDetails[
                    `${extraFieldDetails[0].id}`
                  ]
                    ? 'yes'
                    : 'no'
                }
                name={extraFieldDetails[0].id}
                sx={{ mt: 2 }}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  handleMedicareExtraChange(e);
                  if (e.target.value !== extraFieldDetails[0].requiredAnswer) {
                    setOpenEligibilityDialog(true);
                  }

                  requiredCheck();
                }}>
                <FormControlLabel
                  value="no"
                  control={<Radio />}
                  label="No"
                  sx={{
                    pr: 2,
                    border: '1px solid #d9d9d9',
                    borderRadius: '4px',
                    backgroundColor: '#fff',
                    marginLeft: '0rem',
                    ...(userAnswer === 'no' &&
                      userAnswer !== extraFieldDetails[0].requiredAnswer && {
                        borderColor: 'error.main',
                      }),
                  }}
                />
                <FormControlLabel
                  value="yes"
                  control={<Radio />}
                  label="Yes"
                  sx={{
                    pr: 2,
                    border: '1px solid #d9d9d9',
                    borderRadius: '4px',
                    backgroundColor: '#fff',
                    marginLeft: '0rem',
                    ...(userAnswer === 'yes' &&
                      userAnswer !== extraFieldDetails[0].requiredAnswer && {
                        borderColor: 'error.main',
                      }),
                  }}
                />
              </RadioGroup>
            </FormControl>
          </Stack>
        </Grid>
      );
    },
    [
      handleMedicareExtraChange,
      medicareExtraDetailsList,
      requiredCheck,
      values.orders,
      values.patient.medicare.advantage,
      values.patient.medicare.partD,
      values.patient.medicareExtraDetails,
    ]
  );

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const medName = queryParams.get('medicationName');
    if (medName && !loadedParams.current) {
      handleMedicationChange(medName, 0, true);
      loadedParams.current = true;
    }
  }, [handleMedicationChange]);

  useEffect(() => {
    if (values.orders.some((order) => order.medicationName !== '')) {
      values.orders.forEach((order, index) => {
        if (order.medicationName !== '' && order.medicationStrength !== '') {
          const selectedMed = medicationsList?.find(
            (item) =>
              item.name ===
              order.medicationName.slice(0, order.medicationName.indexOf(' ('))
          );
          if (selectedMed) {
            setMedicationStrengths((prev) => {
              const newStrengths = [...prev];
              newStrengths[index] = selectedMed.options || null;
              return newStrengths;
            });
            setValues((prev) => ({
              ...prev,
              orders: prev.orders.map((order, i) =>
                i === index
                  ? {
                      ...order,
                      medicationStrength: order.medicationStrength,
                    }
                  : order
              ),
            }));
            setMedCosts((prev) => {
              const newCosts = [...(prev ?? [])];
              newCosts[index] = getMedCost(
                values.orders[index].medicationName.slice(
                  0,
                  values.orders[index].medicationName.indexOf(' (')
                ),
                order.medicationStrength
              );
              return newCosts;
            });
          }
        }
      });
    }
  }, [getMedCost, medicationsList, setValues, values.orders]);
  useEffect(() => {
    if (medicareExtraDetails && !loadingMedicareExtraDetails) {
      setMedicareExtraDetailsList(
        medicareExtraDetails.docs.map((doc) => doc.data())
      );
    }
  }, [loadingMedicareExtraDetails, medicareExtraDetails]);

  return (
    <>
      <Box className="tprx-card">
        <Stack>
          <Typography
            variant="h2"
            textAlign={'center'}
            color={theme.palette.primary.main}>
            Great, now pick the medications you need
          </Typography>
          <Typography variant="h3" textAlign={'center'}>
            Based on your earlier details, we'll show you your plan options and
            costs - or even if it's free!
          </Typography>
        </Stack>
        <Grid container spacing={2}>
          {numMeds > 0 &&
            medicationsList &&
            Array.from({ length: numMeds }, (_, index) => (
              <React.Fragment key={index}>
                <Grid item xs={12} sm={4}>
                  <Autocomplete
                    filterOptions={filterMedicationsOptions}
                    disablePortal
                    options={medicationsList}
                    getOptionLabel={(option: any) =>
                      `${option.name} (${option.genericName})`
                    }
                    value={
                      medicationsList.find(
                        (option) =>
                          option.name ===
                          values.orders[index].medicationName.split(' (')[0]
                      ) || null
                    }
                    onChange={(_e, value: any) => {
                      handleMedicationChange(
                        !value ? '' : `${value.name} (${value.genericName})`,
                        index,
                        false
                      );
                      if (!value) {
                        handleStrengthChange(
                          {
                            target: { value: '' },
                          } as ChangeEvent<HTMLInputElement>,
                          index
                        );
                      }
                    }}
                    renderOption={(props, option: any) => {
                      const { key, ...optionProps } = props;
                      return (
                        <Stack
                          key={key}
                          component="li"
                          {...optionProps}
                          direction={'row'}>
                          <Stack direction={'column'} mr={'auto'}>
                            <Typography variant="body1">
                              {option.name}
                            </Typography>
                            <Typography variant="body2">
                              ({option.genericName})
                            </Typography>
                          </Stack>
                          <Typography
                            variant="body2"
                            sx={{
                              fontSize: '1.1rem',
                              fontWeight: 600,
                              backgroundColor: option.brand
                                ? theme.palette.primary.dark
                                : theme.palette.primary.main,
                              color: theme.palette.common.white,
                              padding: '0.2rem 0.5rem',
                              borderRadius: '0.5rem',
                              marginLeft: '2rem',
                            }}>
                            {option.brand ? 'Brand' : 'Generic'}
                          </Typography>
                        </Stack>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required={index === 0}
                        fullWidth
                        name={'medicationName'}
                        value={values.orders[index]['medicationName'] || null}
                        label={'Select Medication'}
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required={isFirstTextFieldChanged[index]}
                    key={`choosestrength-${index}`}
                    fullWidth
                    select
                    name={`medicationStrength`}
                    value={values.orders[index].medicationStrength || ''}
                    label={'Choose Medication Strength'}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      handleStrengthChange(e, index);
                    }}
                    variant="outlined">
                    {medicationStrengths[index] ? (
                      medicationStrengths[index]!.map((option) => (
                        <MenuItem key={option.strength} value={option.strength}>
                          {option.strength}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value={''}>Choose Medication First</MenuItem>
                    )}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Stack justifyContent={'center'} height={'100%'}>
                    <Typography fontWeight={600}>
                      {(medCosts && medCosts[index]) ||
                        'Select Medication & Strength'}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="body2"
                    color={'textSecondary'}
                    fontWeight={500}>
                    {medicationDetailsText[index]}
                  </Typography>
                </Grid>
                {values.patient.insurance.company === 'medicare' && // <>
                  //   {values.orders[index].medicationName.split(' (')[0] ===
                  //     'Eliquis' && (
                  //     <Grid item xs={12}>
                  //       <Stack spacing={2}>
                  //         <FormControl required>
                  //           <FormLabel id="pharmacyExceeds3">
                  //             Does your pharmacy expenses exceeds 3% of your
                  //             yearly household income?
                  //           </FormLabel>
                  //           <RadioGroup
                  //             row
                  //             aria-labelledby="pharmacyExceeds3"
                  //             value={
                  //               values.patient.medicareExtraDetails
                  //                 .pharmacyExceeds3
                  //                 ? 'yes'
                  //                 : 'no'
                  //             }
                  //             name="pharmacyExceeds3"
                  //             sx={{ mt: 2 }}
                  //             onChange={(
                  //               e: ChangeEvent<HTMLInputElement>
                  //             ) => {
                  //               handleMedicareExtraChange(e);
                  //               if (e.target.value === 'no') {
                  //                 setOpenEligibilityDialog(true);
                  //                 setValidStep(false);
                  //               }
                  //             }}>
                  //             <FormControlLabel
                  //               value="no"
                  //               control={<Radio />}
                  //               label="No"
                  //               sx={{
                  //                 pr: 2,
                  //                 border: '1px solid #d9d9d9',
                  //                 borderRadius: '4px',
                  //                 backgroundColor: '#fff',
                  //                 marginLeft: '0rem',
                  //               }}
                  //             />
                  //             <FormControlLabel
                  //               value="yes"
                  //               control={<Radio />}
                  //               label="Yes"
                  //               sx={{
                  //                 pr: 2,
                  //                 border: '1px solid #d9d9d9',
                  //                 borderRadius: '4px',
                  //                 backgroundColor: '#fff',
                  //                 marginLeft: '0rem',
                  //               }}
                  //             />
                  //           </RadioGroup>
                  //         </FormControl>
                  //         <Typography variant="body2">
                  //           You will need to provide a proof of your
                  //           pharmacy expenses to the manufacturer to be
                  //           approved
                  //         </Typography>
                  //       </Stack>
                  //     </Grid>
                  //   )}
                  //   {jjGroup.some(
                  //     (med) =>
                  //       values.orders[index].medicationName.split(
                  //         ' ('
                  //       )[0] === med
                  //   ) && (
                  //     <Grid item xs={12}>
                  //       <Stack
                  //         spacing={2}
                  //         maxWidth={{
                  //           xs: '100%',
                  //           sm: '80%',
                  //           md: '60%',
                  //         }}>
                  //         <FormControl required>
                  //           <FormLabel id="pharmacyExceeds4">
                  //             Does your household pharmacy expenses exceeds
                  //             4% of your yearly household gross income?
                  //           </FormLabel>
                  //           <RadioGroup
                  //             row
                  //             aria-labelledby="pharmacyExceeds4"
                  //             value={
                  //               values.patient.medicareExtraDetails
                  //                 .pharmacyExceeds4
                  //                 ? 'yes'
                  //                 : 'no'
                  //             }
                  //             name="pharmacyExceeds4"
                  //             sx={{ mt: 2 }}
                  //             onChange={(
                  //               e: ChangeEvent<HTMLInputElement>
                  //             ) => {
                  //               handleMedicareExtraChange(e);
                  //               if (e.target.value === 'no') {
                  //                 setOpenEligibilityDialog(true);
                  //                 setValidStep(false);
                  //               }
                  //             }}>
                  //             <FormControlLabel
                  //               value="no"
                  //               control={<Radio />}
                  //               label="No"
                  //               sx={{
                  //                 pr: 2,
                  //                 border: '1px solid #d9d9d9',
                  //                 borderRadius: '4px',
                  //                 backgroundColor: '#fff',
                  //               }}
                  //             />
                  //             <FormControlLabel
                  //               value="yes"
                  //               control={<Radio />}
                  //               label="Yes"
                  //               sx={{
                  //                 pr: 2,
                  //                 border: '1px solid #d9d9d9',
                  //                 borderRadius: '4px',
                  //                 backgroundColor: '#fff',
                  //               }}
                  //             />
                  //           </RadioGroup>
                  //         </FormControl>
                  //       </Stack>
                  //     </Grid>
                  //   )}
                  //   {spend600.some(
                  //     (med) =>
                  //       values.orders[index].medicationName.split(
                  //         ' ('
                  //       )[0] === med
                  //   ) && (
                  //     <Grid item xs={12}>
                  //       <Stack
                  //         spacing={2}
                  //         maxWidth={{
                  //           xs: '100%',
                  //           sm: '80%',
                  //           md: '60%',
                  //         }}>
                  //         <FormControl required>
                  //           <FormLabel id="spend600">
                  //             Did you spend a total of $600 for
                  //             prescriptions in the current calendar year?
                  //           </FormLabel>
                  //           <RadioGroup
                  //             row
                  //             aria-labelledby="spend600"
                  //             value={
                  //               values.patient.medicareExtraDetails.spend600
                  //                 ? 'yes'
                  //                 : 'no'
                  //             }
                  //             name="spend600"
                  //             sx={{ mt: 2 }}
                  //             onChange={(
                  //               e: ChangeEvent<HTMLInputElement>
                  //             ) => {
                  //               handleMedicareExtraChange(e);
                  //               if (e.target.value === 'no') {
                  //                 setOpenEligibilityDialog(true);
                  //                 setValidStep(false);
                  //               }
                  //             }}>
                  //             <FormControlLabel
                  //               value="no"
                  //               control={<Radio />}
                  //               label="No"
                  //               sx={{
                  //                 pr: 2,
                  //                 border: '1px solid #d9d9d9',
                  //                 borderRadius: '4px',
                  //                 backgroundColor: '#fff',
                  //               }}
                  //             />
                  //             <FormControlLabel
                  //               value="yes"
                  //               control={<Radio />}
                  //               label="Yes"
                  //               sx={{
                  //                 pr: 2,
                  //                 border: '1px solid #d9d9d9',
                  //                 borderRadius: '4px',
                  //                 backgroundColor: '#fff',
                  //               }}
                  //             />
                  //           </RadioGroup>
                  //         </FormControl>
                  //       </Stack>
                  //     </Grid>
                  //   )}
                  // </>
                  extraMedicareFields(index)}
              </React.Fragment>
            ))}
        </Grid>
        <Stack
          spacing={2}
          direction={{
            xs: 'column',
            sm: 'row',
          }}
          justifyContent={'space-between'}
          width={'100%'}>
          <Button
            variant="outlined"
            onClick={() => {
              setNumMeds((prev) => prev + 1);
              values.orders.push({
                doctorName: '',
                medicationName: '',
                medicationStrength: '',
                medicationDelivery: '',
              });
              setMedicationStrengths((prev) => [...prev, null]);
            }}>
            Add Medication
          </Button>
        </Stack>
      </Box>
      <QualificationDialog
        open={openEligibilityDialog}
        handleClose={handleClose}
        qulificationGroup={'who are under 18'}
        isEligible={true}
        isMedicare={values.patient.insurance.company === 'medicare'}
        medName={currentMedName || ''}
        medExist={medExist}
      />
    </>
  );
}
export default SecondStep;
